import React from 'react'
import { type Dayjs } from 'dayjs'

import { BlotterInputType } from './common/types'
import {
  AccountEditContent,
  BooleanEditContent,
  EntityEditContent,
  InstrumentEditContent,
  NumberEditContent,
  StrategyEditContent,
  StringEditContent,
  TimeEditContent,
  TraderEditContent,
  InvestorEditContent,
  DeskEditContent,
  TransactionTypeEditContent
} from './modals'

export function getBlotterEditModalContent<O> (
  inputType: BlotterInputType,
  label: string,
  editProperty: keyof O,
  closeModal: () => void,
  handleSubmission: (property: keyof O, value: any, selectedIds: readonly string[]) => void,
  newSelected: readonly string[],
  setSelected: (value: string[]) => void,
  initialValueId?: string | null
): React.JSX.Element {
  let content: React.JSX.Element = <></>

  function submit (value: any): void {
    handleSubmission(editProperty, value, newSelected)
    setSelected([])
  }

  function timeSubmit (value: Dayjs): void {
    handleSubmission(editProperty, value.format('YYYY-MM-DD HH:mm'), newSelected)
  }

  switch (inputType) {
    case BlotterInputType.TRADER:
      content = <TraderEditContent
        closeModal={closeModal}
        submit={submit}
        prefillTraderId={initialValueId}
      />
      break
    case BlotterInputType.ENTITY:
      content = <EntityEditContent
        closeModal={closeModal}
        submit={submit}
        prefillEntityId={initialValueId}
      />
      break
    case BlotterInputType.TRANSACTION_TYPE:
      content = <TransactionTypeEditContent
        closeModal={closeModal}
        submit={submit}
        prefillTransactionTypeId={initialValueId}
      />
      break
    case BlotterInputType.BOOLEAN:
      content = <BooleanEditContent
        title={label}
        closeModal={closeModal}
        submit={submit}
      />
      break
    case BlotterInputType.TIME:
      content = <TimeEditContent
        closeModal={closeModal}
        submit={timeSubmit}
      />
      break
    case BlotterInputType.STRING:
      content = <StringEditContent
        title={label}
        closeModal={closeModal}
        submit={submit}
      />
      break
    case BlotterInputType.STRATEGY:
      content = <StrategyEditContent
        closeModal={closeModal}
        submit={submit}
        prefillStrategyId={initialValueId}
      />
      break
    case BlotterInputType.INSTRUMENT:
      content = <InstrumentEditContent
        closeModal={closeModal}
        submit={submit}
        prefillInstrumentId={initialValueId}
      />
      break
    case BlotterInputType.ACCOUNT:
      content = <AccountEditContent
        closeModal={closeModal}
        submit={submit}
        prefillAccountId={initialValueId}
      />
      break
    case BlotterInputType.INVESTOR:
      content = <InvestorEditContent
        closeModal={closeModal}
        submit={submit}
        prefillInvestorId={initialValueId}
      />
      break
    case BlotterInputType.DESK:
      content = <DeskEditContent
        closeModal={closeModal}
        submit={submit}
        prefillDeskId={initialValueId}
      />
      break
    default:
      content = <NumberEditContent
          closeModal={closeModal}
          submit={submit}
          title={label}
        />
  }
  return content
}
