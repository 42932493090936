import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Box, Stack, useTheme } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import {
  type ColumnDefinition,
  Modal,
  ModalOpenButton,
  RefreshButton,
  useRequestSnackbar
} from '@r40cap/ui'
import {
  type AssetChange,
  type Expense,
  type TransactionCreation,
  blotterApi
} from '@r40cap/pms-sdk'

import { expenseDetailsAssetChangeColumns } from './constants'
import type { AssetChangeRow, BlotterBigModal } from './types'
import type { BlotterInputType } from '../common/types'
import SmallLegTable from './SmallLegTable'
import ExpensePanel from './panels/ExpensePanel'
import { AddTransactionToExpenseModal } from './modals/AddTransactionToExpenseModal'
import { isApiError } from '../../../utils/errors'

function ExpenseDetails (): React.JSX.Element {
  const { expenseId } = useParams()
  const navigate = useNavigate()
  const { palette } = useTheme()
  const { showSnackbar } = useRequestSnackbar()
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [isForce, setIsForce] = useState<boolean>(false)
  const {
    data: assetChangesData,
    isFetching: isAssetChangesFetching,
    refetch: assetChangesRefetch
  } = blotterApi.useGetExpenseAssetChangesQuery({
    force: isForce,
    expenseId: expenseId ?? ''
  }, {
    skip: isInitialLoad
  })
  const {
    data: expenseData,
    isFetching: isExpenseFetching,
    refetch: expenseRefetch
  } = blotterApi.useGetExpenseByIdQuery({
    force: isForce,
    expenseId: expenseId ?? ''
  }, {
    skip: isInitialLoad
  })
  const [postTransaction] = blotterApi.useAddTransactionsMutation()

  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false)
    }
  }, [isInitialLoad])

  function exitModal (): void {
    if ((Boolean(window.history.state)) && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate('/blotter/expenses')
    }
  }

  const [expense, setExpense] = useState<Expense | null>(null)

  useEffect(() => {
    setExpenseResetSignal(!expenseResetSignal)
    if (expenseData !== undefined && expenseData !== null) {
      setExpense(expenseData.data)
    } else {
      setExpense(null)
    }
  }, [expenseData])

  const [assetChangesSetDataSignal, setAssetChangesSetDataSignal] = useState<boolean>(false)
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false)
  const [expenseResetSignal, setExpenseResetSignal] = useState<boolean>(false)

  function refresh (): void {
    if (!isInitialLoad) {
      if (!isForce) {
        setIsForce(true)
      } else {
        refreshAssetChangesFunction()
        refreshExpenseFunction()
      }
    }
  }

  function refreshAssetChangesFunction (): void {
    assetChangesRefetch().then(() => {
      setAssetChangesSetDataSignal(!assetChangesSetDataSignal)
    }).catch(() => {
      console.error('Error Refreshing Asset Changes')
    })
  }

  function refreshExpenseFunction (): void {
    expenseRefetch().then(() => {
      setExpenseResetSignal(!expenseResetSignal)
    }).catch(() => {
      console.error('Error Refreshing Loan')
    })
  }

  function addTransactionToExpense (transaction: TransactionCreation): void {
    showSnackbar({
      isOpen: true,
      message: 'Pushing Transaction',
      status: 'processing'
    })
    postTransaction({ additions: [transaction] })
      .then((value) => {
        if (isApiError(value.error)) {
          console.error(value.error.data)
          const msg = value.error.originalStatus === 400
            ? value.error.data
            : 'Unexpected Error, check logs'
          showSnackbar({
            isOpen: true,
            message: msg,
            status: 'error'
          })
        } else {
          showSnackbar({
            isOpen: true,
            message: 'Transaction Added',
            status: 'success'
          })
          setAddModalOpen(false)
        }
      })
      .catch((error) => {
        console.error(error)
        showSnackbar({
          isOpen: true,
          message: 'Failed to post Transaction',
          status: 'error'
        })
      })
  }

  function openLinkedModalInner (relevantId: string, modalType: BlotterBigModal): void {
    if (modalType === 'transaction') {
      navigate(`/blotter/transactions/${relevantId}`)
    } else if (modalType === 'loan') {
      navigate(`/blotter/loans/${relevantId}`)
    }
  }

  return (
    <Modal
      open
      handleClose={exitModal}
    >
      <Box
        sx={{
          width: '95vw',
          height: '95vh',
          overflow: 'scroll'
        }}
      >
        <Stack
          direction={'column'}
          padding={'40px'}
          spacing={2}
        >
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <ModalOpenButton
              modalOpen={addModalOpen}
              setModalOpen={setAddModalOpen}
              modalContents={
                expense !== null
                  ? <AddTransactionToExpenseModal expense={expense} submit={addTransactionToExpense}/>
                  : <></>
              }
            >
              <AddIcon />
            </ModalOpenButton>
            <RefreshButton
              refreshFunction={refresh}
              iconColor={palette.primary.main}
              buttonColor={palette.accent.main}
            />
          </Box>
          <ExpensePanel expense={expense} isFetching={isExpenseFetching} resetSignal={expenseResetSignal}/>
          <SmallLegTable<AssetChangeRow, AssetChange>
            title='Asset Changes'
            columns={expenseDetailsAssetChangeColumns}
            data={assetChangesData}
            isFetching={isAssetChangesFetching}
            openLinkedModal={
              (
                column: ColumnDefinition<AssetChangeRow, BlotterInputType, AssetChange, string, BlotterBigModal>,
                row: AssetChangeRow
              ) => {
                if (column.modalId !== undefined && column.modalType !== undefined) {
                  openLinkedModalInner(row[column.modalId], column.modalType)
                }
              }
            }
            translationFunciton={(change) => {
              return {
                ...change,
                settledTime: change.settledTime ?? '',
                account: `${change.account.platformName}: ${change.account.name}`,
                strategy: (
                  change.strategy === undefined || change.strategy === null ? '' : `${change.strategy.superStrategyName}: ${change.strategy.name}`
                ),
                desk: change.desk?.name ?? '',
                instrument: change.instrument.displayTicker,
                quantityDecimals: change.instrument.quantityDecimals,
                priceDecimals: change.instrument.priceDecimals,
                transactionId: change.associatedTransaction.id,
                expenseId: '',
                expenseReason: '',
                investor: '',
                capitalCallId: '',
                transactionType: change.transactionType?.name ?? '',
                referenceInstrument: change.referenceInstrument?.displayTicker ?? '',
                referencePrice: (change.referencePrice ?? undefined) === undefined ? '' : `${change.referencePrice}`
              }
            }}
            setDataSignal={assetChangesSetDataSignal}
          />
        </Stack>
      </Box>
    </Modal>
  )
}

export default ExpenseDetails
