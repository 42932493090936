import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useTheme
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { StringInput } from '@r40cap/ui'
import {
  type TransactionCreation,
  type LiteInstrument,
  type LiteAccount,
  type LiteStrategy,
  AccountInput,
  StrategyInput,
  type LiteDesk,
  DeskInput,
  type TransactionAssetChange
} from '@r40cap/pms-sdk'

import LegInput from '../../common/BlotterInputs/LegInput'
import { ACCOUNT_TRANSFER_TYPE, GAS_FEE_TYPE } from './constants'

function AccountTransfer (props: {
  submit: (transaction: TransactionCreation) => void
}): React.JSX.Element {
  const { submit } = props
  dayjs.extend(utc)
  const { palette } = useTheme()
  const [sendingAccount, setSendingAccount] = useState<LiteAccount | null>(null)
  const [recievingAccount, setRecievingAccount] = useState<LiteAccount | null>(null)
  const [strategy, setStrategy] = useState<LiteStrategy | null>(null)
  const [desk, setDesk] = useState<LiteDesk | null>(null)
  const [time, setTime] = useState<Dayjs | null>(dayjs().utc())
  const [comments, setComments] = useState<string>('')

  const [instrument, setInstrument] = useState<LiteInstrument | null>(null)
  const [amountStr, setAmountStr] = useState<string>('')
  const [priceStr, setPriceStr] = useState<string>('')

  // Fee Leg
  const [feeInstrument, setFeeInstrument] = useState<LiteInstrument | null>(null)
  const [feeAmtStr, setFeeAmtStr] = useState<string>('')

  const handleChangeTime = (newTime: Dayjs | null): void => {
    if (newTime !== null) {
      setTime(newTime)
    }
  }

  function handleSubmission (): void {
    if (
      time !== null &&
      sendingAccount !== null &&
      recievingAccount !== null &&
      instrument !== null &&
      amountStr !== ''
    ) {
      const changeId1 = uuidv4()
      const changeId2 = uuidv4()
      let assetChanges: TransactionAssetChange[] = [
        {
          id: `new-${changeId1}`,
          account: recievingAccount,
          time: time.format('YYYY-MM-DD HH:mm'),
          settledTime: time.format('YYYY-MM-DD HH:mm'),
          strategy: strategy ?? undefined,
          desk: desk ?? undefined,
          instrument,
          quantity: Math.abs(parseFloat(amountStr)),
          price: Math.abs(parseFloat(priceStr)),
          isFee: false,
          transactionType: {
            id: ACCOUNT_TRANSFER_TYPE,
            name: 'Account Transfer'
          }
        },
        {
          id: `new-${changeId2}`,
          account: sendingAccount,
          time: time.format('YYYY-MM-DD HH:mm'),
          settledTime: time.format('YYYY-MM-DD HH:mm'),
          strategy: strategy ?? undefined,
          desk: desk ?? undefined,
          instrument,
          quantity: -1 * Math.abs(parseFloat(amountStr)),
          price: Math.abs(parseFloat(priceStr)),
          isFee: false,
          transactionType: {
            id: ACCOUNT_TRANSFER_TYPE,
            name: 'Account Transfer'
          }
        }
      ]
      if (
        feeAmtStr !== '' &&
        feeInstrument !== null
      ) {
        const feeChangeId = uuidv4()
        assetChanges = assetChanges.concat([
          {
            id: `new-${feeChangeId}`,
            time: time.format('YYYY-MM-DD HH:mm'),
            settledTime: time.format('YYYY-MM-DD HH:mm'),
            account: sendingAccount,
            strategy: strategy ?? undefined,
            desk: desk ?? undefined,
            instrument: feeInstrument,
            quantity: -1 * Math.abs(parseFloat(feeAmtStr)),
            price: 0,
            isFee: true,
            transactionType: {
              id: GAS_FEE_TYPE,
              name: 'Gas'
            }
          }
        ])
      }
      const txnId = uuidv4()
      const txn: TransactionCreation = {
        id: `new-${txnId}`,
        trader: undefined,
        expectedSettlementTime: undefined,
        comments,
        entryPlatform: 'Frontend',
        entryPlatformId: `FE-${txnId}`,
        assetChanges,
        openedLiabilities: [],
        closedLiabilities: []
      }
      submit(txn)
    }
  }

  return (
    <Stack spacing={1}>
      <Grid spacing={1} container sx={{ width: '100%' }}>
        <Grid item xs={6}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Box sx={{ width: '50%' }}>
              <Typography
                sx={{ color: palette.tableBodyText.main }}
              >
                Time
              </Typography>
            </Box>
            <Box sx={{ width: '50%' }}>
              <DateTimePicker
                value={time}
                format='YYYY-MM-DD HH:mm'
                onChange={handleChangeTime}
                closeOnSelect={false}
                ampm={false}
                timeSteps={{ hours: 1, minutes: 1 }}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <StringInput
            value={comments}
            setValue={setComments}
            title='Comments'
            color={palette.accent.main}
          />
        </Grid>
        <Grid item xs={6}>
          <StrategyInput
            setStrategy={setStrategy}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            enhanced={false}
            includeSuperStrategyFilter
          />
        </Grid>
        <Grid item xs={6}>
          <DeskInput
            setDesk={setDesk}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            enhanced={false}
          />
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={1} sx={{ width: '100%' }}>
            <Typography
              sx={{ color: palette.tableBodyText.main }}
            >
              From
            </Typography>
            <AccountInput
              setAccount={setSendingAccount}
              setNone={(dummy: boolean) => {}}
              allowNone={false}
              mainColor={palette.accent.main}
              secondaryColor={palette.tableBodyText.main}
              includeEntityFilter
              enhanced
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={1} sx={{ width: '100%' }}>
            <Typography
              sx={{ color: palette.tableBodyText.main }}
            >
              To
            </Typography>
            <AccountInput
              setAccount={setRecievingAccount}
              setNone={(dummy: boolean) => {}}
              allowNone={false}
              mainColor={palette.accent.main}
              secondaryColor={palette.tableBodyText.main}
              includeEntityFilter
              enhanced
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <LegInput
            legName='Transferred'
            amountStr={amountStr}
            setAmountStr={setAmountStr}
            priceStr={priceStr}
            setPriceStr={setPriceStr}
            setInstrument={setInstrument}
          />
        </Grid>
        <Grid item xs={12}>
          <LegInput
            legName='Gas Fee'
            amountStr={feeAmtStr}
            setAmountStr={setFeeAmtStr}
            setInstrument={setFeeInstrument}
          />
        </Grid>
      </Grid>
      <Button
        onClick={handleSubmission}
        variant='contained'
        style={{
          width: '100%',
          backgroundColor: palette.accent.main,
          color: palette.secondary.main
        }}
      >
        Submit
      </Button>
    </Stack>
  )
}

export default AccountTransfer
