import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import {
  type ColumnDefinition,
  HeaderCell,
  type Order,
  RowSkeleton,
  getComparator,
  stableSort
} from '@r40cap/ui'
import { type Algo, type AlgoPosition, algosApi } from '@r40cap/algos-sdk'
import { priceApi } from '@r40cap/pms-sdk'

import { type AlgoPositionRow } from './types'
import { algoPositionColumns } from './constants'
import AlgoRow from './rows/AlgoRow'

const PRICE_REFRESH_INTERVAL = 1000 * 60 * 5

function AlgoPositionsTableHeader (props: {
  onRequestSort: (property: keyof AlgoPositionRow) => void
  order: Order
  orderBy: keyof AlgoPositionRow
  columns: Array<ColumnDefinition<AlgoPositionRow, any>>
  openFirst: () => void
  openSecond: () => void
  closeAll: () => void
  firstOpenDesc: string
  secondOpenDesc: string
  isLoading: boolean
}): React.JSX.Element {
  const { palette } = useTheme()
  const {
    onRequestSort,
    order,
    orderBy,
    columns,
    openFirst,
    openSecond,
    closeAll,
    firstOpenDesc,
    secondOpenDesc,
    isLoading
  } = props
  // Starting with the bases open
  const [clickTracker, setClickTracker] = useState<number>(1)
  const [buttonText, setButtonText] = useState<string>(secondOpenDesc)

  const createSortHandler = (property: keyof AlgoPositionRow): void => {
    onRequestSort(property)
  }

  function handleToggle (): void {
    if (clickTracker === 0) {
      openFirst()
      setClickTracker(1)
      setButtonText(secondOpenDesc)
    } else if (clickTracker === 1) {
      openSecond()
      setClickTracker(2)
      setButtonText('Close')
    } else {
      closeAll()
      setClickTracker(0)
      setButtonText(firstOpenDesc)
    }
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ color: palette.tertiary.main, padding: 1 }}>
          <Button
            variant='outlined'
            disabled={isLoading}
            onClick={handleToggle}
            sx={{
              color: palette.accent.main,
              fontSize: 9
            }}
          >
            {buttonText}
          </Button>
        </TableCell>
        {columns.map((column, idx) => (
          <HeaderCell<AlgoPositionRow, any>
            column={column}
            isActiveSort={orderBy === column.id}
            order={order}
            sortHandler={createSortHandler}
            key={column.id}
            backgroundColor={palette.background.default}
            textColor={palette.tableHeaderText.main}
            activeTextColor={palette.accent.main}
            dense
          />
        ))}
      </TableRow>
    </TableHead>
  )
}

function getUniqueAlgos (positions: AlgoPosition[]): AlgoPositionRow[] {
  const filteredObjects = positions.reduce((accumulator: Record<string, AlgoPosition>, currentObject: AlgoPosition) => {
    accumulator[currentObject.algo] = currentObject
    return accumulator
  }, {})
  const uniqueObjects: AlgoPosition[] = Object.values(filteredObjects)
  return uniqueObjects.map((psn) => {
    return {
      rowName: psn.algo,
      quantityDecimals: 0,
      colorSign: 0
    }
  })
}

function AlgoPositionsTableBody (props: {
  positions: AlgoPosition[]
  isLoading: boolean
  order: Order
  orderBy: keyof AlgoPositionRow
  columns: Array<ColumnDefinition<AlgoPositionRow, any>>
  openedMap: Map<string, string[]>
  toggleAlgo: (algo: string) => void
  toggleBase: (algo: string, baseFx: string) => void
  baseTickerToPrice?: Map<string, number>
  pxIsLoading: boolean
  algosMap: Map<string, Algo>
}): React.JSX.Element {
  const {
    positions,
    isLoading,
    order,
    orderBy,
    columns,
    openedMap,
    toggleAlgo,
    toggleBase,
    baseTickerToPrice,
    pxIsLoading,
    algosMap
  } = props
  const [algoRows, setAlgoRows] = useState<AlgoPositionRow[]>(getUniqueAlgos(positions))

  const visibleAlgos = useMemo(
    () => {
      return stableSort(algoRows, getComparator('asc', 'rowName'))
    },
    [order, orderBy, algoRows]
  )

  useEffect(() => {
    setAlgoRows(getUniqueAlgos(positions))
  }, [positions])

  if (isLoading) {
    const rows = []
    for (let i = 0; i < 5; i++) {
      rows.push(
        <RowSkeleton
          usedKey={i}
          columns={columns}
          frontBuffer={{
            key: 'toggle',
            alignment: 'center',
            variant: 'rectangular'
          }}
          key={i}
        />
      )
    }
    return (
      <TableBody>
        {rows}
        <TableRow sx={{ height: '100%' }} />
      </TableBody>
    )
  } else if (positions.length === 0) {
    return (
      <TableBody>
        <TableRow sx={{ height: '100%' }}>
          <TableCell
            colSpan={columns.length + 1}
            align='center'
          >
            <Typography sx={{ fontSize: 20 }}>No Data</Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    )
  } else {
    const rows = visibleAlgos.map((algo) => {
      return (
        <AlgoRow
          rowName={algo.rowName}
          bottomRows={positions.filter((psn) => (psn.algo) === algo.rowName)}
          key={algo.rowName}
          order={order}
          orderBy={orderBy}
          columns={columns}
          isOpen={openedMap.has(algo.rowName)}
          toggleOpen={() => { toggleAlgo(algo.rowName) }}
          openChildren={openedMap.get(algo.rowName) ?? []}
          openChild={(baseFx: string) => { toggleBase(algo.rowName, baseFx) }}
          baseTickerToPrice={baseTickerToPrice}
          pxIsLoading={pxIsLoading}
          algoDetails={algosMap.get(algo.rowName)}
        />
      )
    })
    return (
      <TableBody>
        {rows}
        <TableRow sx={{ height: '100%' }} />
      </TableBody>
    )
  }
}

function AlgoPositionsTable (props: { refreshSignal: boolean }): React.JSX.Element {
  const { refreshSignal } = props
  const { palette } = useTheme()

  const {
    data: algoPsnsData,
    isFetching: algoPsnsIsFetching,
    refetch: algoPsnsRefetch
  } = algosApi.useGetAlgoPositionsQuery({})
  const { data: pxData, refetch: pxRefetch, isLoading: pxIsLoading } = priceApi.useGetPricesQuery({})
  const { data: algosData } = algosApi.useGetAlgosQuery({})

  const [openedMap, setOpenedMap] = useState(new Map<string, string[]>())
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof AlgoPositionRow>('grossQuantity')
  const [baseTickerToPrice, setBaseTickerToPrice] = useState<Map<string, number>>(new Map())
  const [algosMap, setAlgosMap] = useState<Map<string, Algo>>(new Map())

  const handleRequestSort = (property: keyof AlgoPositionRow): void => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  useEffect(() => {
    algoPsnsRefetch()
      .catch((error) => { console.error(error) })
  }, [refreshSignal])

  useEffect(() => {
    if (algoPsnsData !== null && algoPsnsData !== undefined) {
      const algoIds = algoPsnsData.data.map((val) => val.algo)
      setOpenedMap(new Map(algoIds.map((val) => [val, algoPsnsData.data.filter((psn) => psn.algo === val).map((psn) => psn.baseFx)])))
    }
  }, [algoPsnsData])

  useEffect(() => {
    if (algosData !== null && algosData !== undefined) {
      setAlgosMap(new Map(algosData.data.map((val) => [val.algoId, val])))
    }
  }, [algosData])

  useEffect(() => {
    if (pxData !== null && pxData !== undefined) {
      setBaseTickerToPrice(new Map(pxData.data.filter((price) => {
        return price.instrument.displayTicker.split(' ').length === 1
      }).map((val) => [val.instrument.displayTicker, val.price])))
    }
  }, [pxData])

  useEffect(() => {
    const interval = setInterval(() => {
      pxRefetch().catch((error) => { console.error(error) })
    }, PRICE_REFRESH_INTERVAL)
    return () => { clearInterval(interval) }
  }, [pxRefetch])

  return (
    <TableContainer
      sx={{
        backgroundColor: palette.primary.main,
        height: '100%',
        borderRadius: '5px'
      }}
    >
      <Table stickyHeader sx={{ height: '100%' }}>
        <AlgoPositionsTableHeader
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          columns={algoPositionColumns}
          closeAll={() => { setOpenedMap(new Map<string, string[]>()) }}
          openFirst={() => {
            if (algoPsnsData !== null && algoPsnsData !== undefined) {
              const algoIds = algoPsnsData.data.map((val) => val.algo)
              setOpenedMap(new Map(algoIds.map((val) => [val, []])))
            }
          }}
          openSecond={() => {
            const newMap = new Map<string, string[]>()
            if (algoPsnsData !== null && algoPsnsData !== undefined) {
              const algoIds = algoPsnsData.data.map((val) => val.algo)
              for (const id of algoIds) {
                newMap.set(id, algoPsnsData.data.filter((val) => val.algo === id).map((val) => val.baseFx))
              }
            }
            setOpenedMap(newMap)
          }}
          firstOpenDesc='Open Algos'
          secondOpenDesc='Open Bases'
          isLoading={algoPsnsIsFetching}
        />
        <AlgoPositionsTableBody
          isLoading={algoPsnsIsFetching}
          positions={algoPsnsData?.data ?? []}
          order={order}
          orderBy={orderBy}
          columns={algoPositionColumns}
          openedMap={openedMap}
          toggleAlgo={(algo) => {
            if (openedMap.has(algo)) {
              openedMap.delete(algo)
            } else {
              openedMap.set(algo, [])
            }
            setOpenedMap(new Map(openedMap))
          }}
          toggleBase={(strategyId, baseId) => {
            const bases = openedMap.get(strategyId)
            if (bases !== undefined) {
              if (bases.includes(baseId)) {
                openedMap.set(strategyId, bases.filter((val) => val !== baseId))
              } else {
                openedMap.set(strategyId, [...bases, baseId])
              }
              setOpenedMap(new Map(openedMap))
            }
          }}
          baseTickerToPrice={baseTickerToPrice ?? undefined}
          pxIsLoading={pxIsLoading}
          algosMap={algosMap}
        />
      </Table>
    </TableContainer>
  )
}

export default AlgoPositionsTable
