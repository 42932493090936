// GENERAL
export const COMMISSION_LEG_TYPE = 'f76891fd-af52-4aed-bc00-f651c175c583'

// SPOT
export const SPOT_BASE_LEG_TYPE = '66113e42-3fcb-43f8-9b5f-9882d0d67ac4'
export const SPOT_QUOTE_LEG_TYPE = 'd9606614-2968-48a3-b773-cc476c664e87'

// FUTURES
export const FUTURE_TRADE_TYPE = 'b48ca88f-be96-4d70-94d5-732cf1c8c3a5'

// PERPS
export const PERPS_TRADE_TYPE = '8f985be9-c6cc-4f40-a6bc-1f83f06e8b6f'

// Options
export const OPTIONS_BASE_LEG_TYPE = '6098ca60-8da8-4f12-8ff1-0a16605bf3b9'
export const OPTIONS_PREMIUM_LEG_TYPE = '5ec6179f-3fd1-4a05-9431-0afdd1bfe505'

// STRATEGY TRANSFER
export const STRATEGY_TRANSFER_TYPE = 'd80361ad-2b12-4415-ac8a-2e38369d6005'

// ACCOUNT TRANSFER
export const ACCOUNT_TRANSFER_TYPE = '6c42e492-dd91-4921-8f77-ec55aaa35747'
export const GAS_FEE_TYPE = '1f52c0ed-c304-4eb0-9c46-b2a5b1ee1542'
