import React, { useMemo } from 'react'
import dayjs, { type Dayjs } from 'dayjs'
import minMax from 'dayjs/plugin/minMax'
import utc from 'dayjs/plugin/utc'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'

import type { PositionObject, RpmsPositionRow } from '../types'
import { HeaderCell, stableSort, getComparator } from '@r40cap/ui'
import { rpmsPositionsColumns } from '../constants'
import { ValueCellWithColor } from './cells'

function RpmsPositionsSubTableRow (props: {
  containedRows: PositionObject[]
  colsToSpan: number
  includeSmallAmounts: boolean
  smallThreshold: number
  requestedTime?: Dayjs
}): React.JSX.Element {
  dayjs.extend(minMax)
  dayjs.extend(utc)
  const { palette } = useTheme()
  const {
    colsToSpan,
    containedRows,
    includeSmallAmounts,
    smallThreshold
  } = props

  const visibleRows = useMemo(
    () => {
      const filteredForSmallRows = includeSmallAmounts
        ? containedRows
        : containedRows.filter((row) => row.price === undefined || Math.abs(row.quantity * row.price * row.multiplier) > smallThreshold)
      return stableSort(filteredForSmallRows, (a, b) => {
        return getComparator('desc', 'marketValue')({
          marketValue: a.price === undefined ? undefined : Math.abs(a.quantity * a.price * a.multiplier)
        },
        {
          marketValue: b.price === undefined ? undefined : Math.abs(b.quantity * b.price * b.multiplier)
        })
      })
    },
    [containedRows, includeSmallAmounts, smallThreshold]
  )

  return (
    <TableRow sx={{ backgroundColor: palette.tertiary.main }}>
      {
        visibleRows.length === 0
          ? <TableCell colSpan={colsToSpan} align='center' sx={{ alignContent: 'center' }}>
            <Typography variant='h6'>
              {
                containedRows.length === 0
                  ? 'No Positions'
                  : `Hiding ${containedRows.length} Small ${containedRows.length === 1 ? 'Position' : 'Positions'}`
              }
            </Typography>
          </TableCell>
          : <>
            <TableCell colSpan={1} sx={{ alignContent: 'start' }}>
              <Typography variant='h6'>Positions</Typography>
            </TableCell>
            <TableCell colSpan={colsToSpan - 1}>
              <Table size='small' sx={{ borderRadius: '3px' }}>
                <TableBody>
                  <TableRow>
                    {
                      rpmsPositionsColumns.map((column, idx) => (
                        <HeaderCell<RpmsPositionRow, any>
                          column={column}
                          isActiveSort={false}
                          order={'asc'}
                          sortHandler={(): void => {}}
                          key={column.id}
                          backgroundColor={palette.background.default}
                          textColor={palette.tableHeaderText.main}
                          activeTextColor={palette.accent.main}
                          dense
                        />
                      ))
                    }
                  </TableRow>
                  {
                    visibleRows.map((row) => (
                      <TableRow key={row.instrumentId}>
                        {
                          rpmsPositionsColumns.map((column, index) => (
                            column.isDummyColumn
                              ? <TableCell key={column.id} />
                              : <ValueCellWithColor<RpmsPositionRow, any>
                                column={column}
                                item={{
                                  instrumentName: row.instrumentName,
                                  quantity: row.quantity,
                                  multiplier: row.multiplier,
                                  price: row.price,
                                  delta: row.delta,
                                  marketValue: (row.price != null && !isNaN(row.price) && row.quantity != null && !isNaN(row.quantity)) ? row.price * row.quantity * row.multiplier : undefined,
                                  quantityDecimals: row.quantityDecimals,
                                  priceDecimals: row.priceDecimals,
                                  receivedTime: dayjs.utc(row.priceTime),
                                  requestedTime: props.requestedTime
                                }}
                                key={column.id}
                                defaultTextColor={palette.tableBodyText.main}
                                redTextColor='red'
                                greenTextColor='green'
                              />
                          ))
                        }
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableCell>
          </>
      }
    </TableRow>
  )
}

export default RpmsPositionsSubTableRow
