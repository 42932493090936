import React, { useEffect, useMemo, useState } from 'react'
import type { Dayjs } from 'dayjs'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import { useAuth } from '@r40cap/auth'
import {
  HeaderCell,
  type Order,
  RowSkeleton,
  getComparator,
  stableSort
} from '@r40cap/ui'
import {
  type PositionByStrategy,
  type Price,
  portfolioApi
} from '@r40cap/pms-sdk'

import {
  UNTAGGED_ID,
  UNTAGGED_COLOR,
  UNTAGGED_NAME,
  UNTAGGED_PRIORITY,
  strategyColumns
} from './constants'
import {
  type ColumnDefinitionWithStaleness,
  type PositionRow,
  type PositionWithPrice
} from './types'
import {
  FirstLevelRowWithPriority,
  PositionsTableFooter
} from './rows'
import { type DeskOption } from '../../common/types'

interface FirstLevelDefWithPriority {
  name: string
  id: string
  priority: number
  color: string
}

function mergePositions (positions: PositionByStrategy[]): PositionByStrategy[] {
  const combinedMap: Record<string, PositionByStrategy> = {}
  positions.forEach(position => {
    const key = `${position.strategyId ?? UNTAGGED_ID}-${position.instrumentId}`
    if (combinedMap[key] !== undefined) {
      combinedMap[key].quantity += position.quantity
    } else {
      combinedMap[key] = { ...position }
    }
  })
  return Object.values(combinedMap)
}

function getUniqueStrategies (positions: PositionWithPrice[]): FirstLevelDefWithPriority[] {
  const filteredObjects = positions.reduce((accumulator: Record<string, PositionWithPrice>, currentObject: PositionWithPrice) => {
    accumulator[currentObject.strategyId ?? UNTAGGED_ID] = currentObject
    return accumulator
  }, {})
  const uniqueObjects: PositionWithPrice[] = Object.values(filteredObjects)
  return uniqueObjects.map((psn) => {
    return {
      id: psn.strategyId ?? UNTAGGED_ID,
      name: ((psn.superStrategyName ?? undefined) !== undefined && (psn.strategyName ?? undefined) !== undefined)
        ? `${psn.superStrategyName} - ${psn.strategyName}`
        : UNTAGGED_NAME,
      priority: psn.strategyPriority ?? UNTAGGED_PRIORITY,
      color: psn.strategyColor ?? UNTAGGED_COLOR
    }
  })
}

function TwoLevelTableHeadWithPriority (props: {
  onRequestSort: (property: keyof PositionRow) => void
  order: Order
  orderBy: keyof PositionRow
  columns: Array<ColumnDefinitionWithStaleness<PositionRow, any>>
  openFirst: () => void
  openSecond: () => void
  closeAll: () => void
  firstOpenDesc: string
  secondOpenDesc: string
  isLoading: boolean
}): React.JSX.Element {
  const { palette } = useTheme()
  const {
    onRequestSort,
    order,
    orderBy,
    columns,
    openFirst,
    openSecond,
    closeAll,
    firstOpenDesc,
    secondOpenDesc,
    isLoading
  } = props
  const [clickTracker, setClickTracker] = useState<number>(0)
  const [buttonText, setButtonText] = useState<string>(firstOpenDesc)

  const createSortHandler = (property: keyof PositionRow): void => {
    onRequestSort(property)
  }

  function handleToggle (): void {
    if (clickTracker === 0) {
      openFirst()
      setClickTracker(1)
      setButtonText(secondOpenDesc)
    } else if (clickTracker === 1) {
      openSecond()
      setClickTracker(2)
      setButtonText('Close')
    } else {
      closeAll()
      setClickTracker(0)
      setButtonText(firstOpenDesc)
    }
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ color: palette.tertiary.main, padding: 1 }}>
          <Button
            variant='outlined'
            disabled={isLoading}
            onClick={handleToggle}
            sx={{
              color: palette.accent.main,
              fontSize: 9
            }}
          >
            {buttonText}
          </Button>
        </TableCell>
        {columns.map((column, idx) => (
          <HeaderCell<PositionRow, any>
            column={column}
            isActiveSort={orderBy === column.id}
            order={order}
            sortHandler={createSortHandler}
            key={column.id}
            backgroundColor={palette.background.default}
            textColor={palette.tableHeaderText.main}
            activeTextColor={palette.accent.main}
            dense
          />
        ))}
      </TableRow>
    </TableHead>
  )
}

function PositionsByStrategyTableBody (props: {
  positions: PositionWithPrice[]
  referencePrice?: Price
  isLoading: boolean
  includeSmallAmounts: boolean
  order: Order
  orderBy: keyof PositionRow
  columns: Array<ColumnDefinitionWithStaleness<PositionRow, any>>
  requestedTime?: Dayjs
  openedMap: Map<string, string[]>
  toggleStrategy: (strategyId: string) => void
  toggleBase: (strategyId: string, baseId: string) => void
  smallThreshold: number
}): React.JSX.Element {
  const {
    positions,
    isLoading,
    order,
    orderBy,
    columns,
    referencePrice,
    includeSmallAmounts,
    requestedTime,
    openedMap,
    toggleStrategy,
    toggleBase,
    smallThreshold
  } = props
  const [strategies, setStrategies] = useState<FirstLevelDefWithPriority[]>(getUniqueStrategies(positions))

  const visibleStrategies = useMemo(
    () => {
      const filteredStrategies = includeSmallAmounts
        ? strategies
        : strategies.filter((strategy) => {
          const stratRows = positions.filter((val) => (val.strategyId ?? UNTAGGED_ID) === strategy.id)
          const absMv = stratRows.reduce((sum, current) => sum + Math.abs(current.multiplier * (current.price ?? 0) * current.quantity), 0)
          return absMv >= smallThreshold
        })

      return stableSort(filteredStrategies, (a, b) => {
        const stratARows = positions.filter((val) => (val.strategyId ?? UNTAGGED_ID) === a.id)
        const psnA = positions.find((val) => (val.strategyId ?? UNTAGGED_ID) === a.id)
        const psnRowA: PositionRow = {
          name: psnA !== undefined ? `${psnA.superStrategyName} - ${psnA.strategyName}` : UNTAGGED_NAME,
          priorityLevel: psnA?.strategyPriority ?? UNTAGGED_PRIORITY,
          strategyColor: psnA?.strategyColor ?? UNTAGGED_COLOR,
          marketValue: stratARows.reduce((sum, current) => sum + current.multiplier * (current.price ?? 0) * current.quantity, 0),
          referenceMarketValue: referencePrice !== undefined
            ? stratARows.reduce((sum, current) => sum + (current.price ?? 0) * current.multiplier * current.quantity, 0) / referencePrice.price
            : stratARows.reduce((sum, current) => sum + (current.price ?? 0) * current.multiplier * current.quantity, 0),
          priceDecimals: 2,
          quantityDecimals: 2
        }
        const stratBRows = positions.filter((val) => (val.strategyId ?? UNTAGGED_ID) === b.id)
        const psnB = positions.find((val) => (val.strategyId ?? UNTAGGED_ID) === b.id)
        const psnRowB: PositionRow = {
          name: psnB !== undefined ? `${psnB.superStrategyName} - ${psnB.strategyName}` : UNTAGGED_NAME,
          priorityLevel: psnB?.strategyPriority ?? UNTAGGED_PRIORITY,
          strategyColor: psnB?.strategyColor ?? UNTAGGED_COLOR,
          marketValue: stratBRows.reduce((sum, current) => sum + current.multiplier * (current.price ?? 0) * current.quantity, 0),
          referenceMarketValue: referencePrice !== undefined
            ? stratBRows.reduce((sum, current) => sum + (current.price ?? 0) * current.multiplier * current.quantity, 0) / referencePrice.price
            : stratBRows.reduce((sum, current) => sum + (current.price ?? 0) * current.multiplier * current.quantity, 0),
          deltaQuantity: stratBRows.reduce((sum, current) => sum + current.multiplier * (current.price ?? 0) * current.quantity, 0),
          priceDecimals: 2,
          quantityDecimals: 2
        }
        return getComparator(order, orderBy)({ ...psnRowA, requestedTime: undefined, receivedTime: undefined }, { ...psnRowB, requestedTime: undefined, receivedTime: undefined })
      })
    },
    [order, orderBy, positions, strategies, includeSmallAmounts, smallThreshold]
  )

  useEffect(() => {
    setStrategies(getUniqueStrategies(positions))
  }, [positions])

  if (isLoading) {
    const rows = []
    for (let i = 0; i < 5; i++) {
      rows.push(
        <RowSkeleton
          usedKey={i}
          columns={columns}
          frontBuffer={{
            key: 'toggle',
            alignment: 'center',
            variant: 'rectangular'
          }}
          key={i}
        />
      )
    }
    return (
      <TableBody>
        {rows}
        <TableRow sx={{ height: '100%' }} />
      </TableBody>
    )
  } else {
    const rows = visibleStrategies.map((strategy) => {
      return (
        <FirstLevelRowWithPriority
          rowName={strategy.name}
          priority={strategy.priority}
          strategyColor={strategy.color}
          bottomRows={positions.filter((psn) => (psn.strategyId ?? UNTAGGED_ID) === strategy.id)}
          key={strategy.id ?? UNTAGGED_ID}
          order={order}
          orderBy={orderBy}
          columns={columns}
          includeSmallAmounts={includeSmallAmounts}
          smallThreshold={smallThreshold}
          referencePrice={referencePrice}
          requestedTime={requestedTime}
          isOpen={openedMap.has(strategy.id)}
          toggleOpen={() => { toggleStrategy(strategy.id) }}
          openChildren={openedMap.get(strategy.id) ?? []}
          openChild={(baseId) => { toggleBase(strategy.id, baseId) }}
        />
      )
    })
    if (rows.length > 0) {
      return (
        <TableBody>
          {rows}
          <TableRow sx={{ height: '100%' }} />
        </TableBody>
      )
    } else {
      return (
        <TableBody>
          <TableRow sx={{ height: '100%' }}>
            <TableCell
              colSpan={columns.length + 1}
              align='center'
            >
              <Typography sx={{ fontSize: 20 }}>No Data</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      )
    }
  }
}

function PositionsByStrategyTable (props: {
  time?: Dayjs
  referencePrice?: Price
  includeUnsettled: boolean
  includeSmallAmounts: boolean
  refreshSignal: boolean
  smallThreshold: number
  desk: DeskOption
  pxData?: Price[]
  pxIsLoading: boolean
}): React.JSX.Element {
  const { palette } = useTheme()
  const authConext = useAuth()
  const {
    time,
    includeUnsettled,
    refreshSignal,
    includeSmallAmounts,
    referencePrice,
    desk,
    smallThreshold,
    pxData,
    pxIsLoading
  } = props

  const [openedMap, setOpenedMap] = useState(new Map<string, string[]>())
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof PositionRow>('priorityLevel')
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [queryParams, setQueryParams] = useState({
    time: time?.format('YYYY-MM-DD HH:mm') ?? undefined,
    includeUnsettled,
    desk: desk.deskIds === undefined || desk.deskIds.length === 0 ? undefined : desk.deskIds.join(','),
    force: false
  })
  const [combinedData, setCombinedData] = useState<PositionWithPrice[]>([])

  const { data: posData, refetch: posRefetch, isFetching: posIsFetching } = portfolioApi.useGetPositionsByStrategyQuery(queryParams, {
    skip: isInitialLoad
  })

  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false)
    }
  }, [isInitialLoad])

  useEffect(() => {
    setQueryParams({
      time: time?.format('YYYY-MM-DD HH:mm') ?? undefined,
      includeUnsettled,
      desk: desk.deskIds === undefined || desk.deskIds.length === 0 ? undefined : desk.deskIds.join(','),
      force: false
    })
  }, [time, includeUnsettled, desk.deskIds, desk.optionId, authConext.restrictedDeskId])

  useEffect(() => {
    if (!isInitialLoad) {
      if (!queryParams.force) {
        setQueryParams((prevParams) => ({
          ...prevParams,
          force: true
        }))
      } else {
        posRefetch().catch(() => {
          console.error('Error Refreshing')
        })
      }
    }
  }, [refreshSignal])

  useEffect(() => {
    if (posData !== null && posData !== undefined) {
      const posDataArray = (desk.deskIds !== undefined && desk.deskIds.length > 0)
        ? posData.data
        : mergePositions(posData.data)
      const combined = posDataArray.map(psn => {
        const effectivePxData = pxIsLoading ? [] : pxData ?? []
        const priceData = effectivePxData.find(px => px.instrument.id === psn.instrumentId)
        return {
          ...psn,
          price: priceData?.price,
          beta: priceData?.beta,
          delta: priceData?.delta,
          priceTime: priceData?.time
        }
      })
      setCombinedData(combined)
    }
  }, [posData, pxData])

  const handleRequestSort = (property: keyof PositionRow): void => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const totalObject: PositionRow = {
    name: 'Fund Total',
    marketValue: combinedData.reduce((partialSum, a) => partialSum + a.multiplier * (a.price ?? 0) * a.quantity, 0) ?? 0,
    referenceMarketValue: (referencePrice !== undefined)
      ? combinedData.reduce((partialSum, a) => partialSum + a.multiplier * (a.price ?? 0) * a.quantity, 0) / referencePrice.price
      : 0,
    quantityDecimals: 2,
    priceDecimals: 2
  }

  const columnsToUse = referencePrice !== undefined
    ? strategyColumns.map((column) => {
      if (column.id === 'referenceMarketValue') {
        const labelToUse = `MV (${referencePrice.instrument.displayTicker})`
        const decToUse = referencePrice.instrument.quantityDecimals
        const valDetsToUse = {
          ...column.valueDetails,
          suffix: `\u00A0${referencePrice.instrument.displayTicker}`
        }
        return {
          ...column,
          label: labelToUse,
          decimals: decToUse,
          valueDetails: valDetsToUse
        }
      } else {
        return column
      }
    })
    : strategyColumns

  return (
    <TableContainer
      sx={{
        backgroundColor: palette.primary.main,
        height: '100%',
        borderRadius: '5px'
      }}
    >
      <Table stickyHeader sx={{ height: '100%' }}>
        <TwoLevelTableHeadWithPriority
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
          columns={columnsToUse}
          closeAll={() => { setOpenedMap(new Map<string, string[]>()) }}
          openFirst={() => {
            const newMap = new Map<string, string[]>(openedMap)
            combinedData.forEach((val) => {
              const key = val.strategyId ?? UNTAGGED_ID
              if (!newMap.has(key)) {
                newMap.set(key, [])
              }
            })
            setOpenedMap(newMap)
          }}
          openSecond={() => {
            const newMap = new Map<string, string[]>(openedMap)
            combinedData.forEach((val) => {
              const key = val.strategyId ?? UNTAGGED_ID
              const existingList = newMap.get(key)
              if (existingList === undefined) {
                newMap.set(key, [val.baseFxId])
              } else {
                newMap.set(key, [...existingList, val.baseFxId])
              }
            })
            setOpenedMap(newMap)
          }}
          firstOpenDesc='Open Strategies'
          secondOpenDesc='Open Bases'
          isLoading={posIsFetching}
        />
        <PositionsByStrategyTableBody
          isLoading={posIsFetching}
          positions={combinedData}
          order={order}
          orderBy={orderBy}
          columns={columnsToUse}
          referencePrice={referencePrice}
          includeSmallAmounts={includeSmallAmounts}
          smallThreshold={pxIsLoading ? 0 : smallThreshold}
          requestedTime={time}
          openedMap={openedMap}
          toggleStrategy={(strategyId) => {
            if (openedMap.has(strategyId)) {
              openedMap.delete(strategyId)
            } else {
              openedMap.set(strategyId, [])
            }
            setOpenedMap(new Map(openedMap))
          }}
          toggleBase={(strategyId, baseId) => {
            const bases = openedMap.get(strategyId)
            if (bases !== undefined) {
              if (bases.includes(baseId)) {
                openedMap.set(strategyId, bases.filter((val) => val !== baseId))
              } else {
                openedMap.set(strategyId, [...bases, baseId])
              }
              setOpenedMap(new Map(openedMap))
            }
          }}
        />
        <PositionsTableFooter
          isLoading={posIsFetching}
          totalObject={totalObject}
          columns={columnsToUse}
        />
      </Table>
    </TableContainer>
  )
}

export default PositionsByStrategyTable
