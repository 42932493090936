import React, { useState } from 'react'
import { Box, Stack } from '@mui/material'
import { usePermissions as useAlgoPermissions } from '@r40cap/algos-sdk'

import NoAccess from './NoAccess'
import Page from './Page'
import AlgosControlsPanel from '../components/algos/AlgosControlsPanel'
import AlgoPositionsTable from '../components/algos/AlgoPositionsTable'

function Algos (): React.JSX.Element {
  const algoPermissions = useAlgoPermissions()
  const [refreshSignal, setRefreshSignal] = useState<boolean>(false)

  const props = { refreshSignal }

  return (
    algoPermissions.username !== undefined && algoPermissions.username !== null
      ? <Page>
          <Stack
            spacing={1}
            direction={'column'}
            width={'100%'}
            sx={{ paddingTop: '90px', paddingBottom: '10px', height: 'calc(100% - 90px - 10px)' }}
          >
            <Box sx={{ height: '7%' }}>
              <AlgosControlsPanel
                refreshFunction={() => { setRefreshSignal(!refreshSignal) }}
              />
            </Box>
            <Box sx={{ height: '93%' }}>
              <AlgoPositionsTable {...props} />
            </Box>
          </Stack>
        </Page>
      : <NoAccess />
  )
}

export default Algos
