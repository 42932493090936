import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useTheme
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { StringInput } from '@r40cap/ui'
import {
  type LiteTrader,
  TraderInput,
  type TransactionCreation,
  type LiteInstrument,
  type LiteAccount,
  type LiteStrategy,
  AccountInput,
  StrategyInput,
  type LiteDesk,
  DeskInput,
  type TransactionAssetChange
} from '@r40cap/pms-sdk'

import LegInput from '../../common/BlotterInputs/LegInput'
import {
  COMMISSION_LEG_TYPE,
  SPOT_BASE_LEG_TYPE,
  SPOT_QUOTE_LEG_TYPE
} from './constants'

function SpotTrade (props: {
  submit: (transaction: TransactionCreation) => void
}): React.JSX.Element {
  const { submit } = props
  dayjs.extend(utc)
  const { palette } = useTheme()
  const [trader, setTrader] = useState<LiteTrader | null>(null)
  const [account, setAccount] = useState<LiteAccount | null>(null)
  const [strategy, setStrategy] = useState<LiteStrategy | null>(null)
  const [desk, setDesk] = useState<LiteDesk | null>(null)
  const [time, setTime] = useState<Dayjs | null>(dayjs().utc())
  const [comments, setComments] = useState<string>('')

  // Base Leg
  const [baseInstrument, setBaseInstrument] = useState<LiteInstrument | null>(null)
  const [baseAmtStr, setBaseAmtStr] = useState<string>('')
  const [basePriceStr, setBasePriceStr] = useState<string>('')
  // Quote Leg
  const [quoteInstrument, setQuoteInstrument] = useState<LiteInstrument | null>(null)
  const [quoteAmtStr, setQuoteAmtStr] = useState<string>('')
  const [quotePriceStr, setQuotePriceStr] = useState<string>('')
  // Fee Leg
  const [feeInstrument, setFeeInstrument] = useState<LiteInstrument | null>(null)
  const [feeAmtStr, setFeeAmtStr] = useState<string>('')

  const handleChangeTime = (newTime: Dayjs | null): void => {
    if (newTime !== null) {
      setTime(newTime)
    }
  }

  function handleSubmission (): void {
    if (
      time !== null &&
      account !== null
    ) {
      let assetChanges: TransactionAssetChange[] = []
      if (
        baseAmtStr !== '' &&
        baseInstrument !== null
      ) {
        const assetChangeId = uuidv4()
        assetChanges = assetChanges.concat([
          {
            id: `new-${assetChangeId}`,
            time: time.format('YYYY-MM-DD HH:mm'),
            settledTime: time.format('YYYY-MM-DD HH:mm'),
            account,
            strategy: strategy ?? undefined,
            desk: desk ?? undefined,
            instrument: baseInstrument,
            quantity: parseFloat(baseAmtStr),
            price: Math.abs(parseFloat(basePriceStr)),
            isFee: false,
            transactionType: {
              id: SPOT_BASE_LEG_TYPE,
              name: 'Spot Base Leg'
            }
          }
        ])
      }
      if (
        quoteAmtStr !== '' &&
        quoteInstrument !== null
      ) {
        const assetChangeId = uuidv4()
        assetChanges = assetChanges.concat([
          {
            id: `new-${assetChangeId}`,
            time: time.format('YYYY-MM-DD HH:mm'),
            settledTime: time.format('YYYY-MM-DD HH:mm'),
            account,
            strategy: strategy ?? undefined,
            desk: desk ?? undefined,
            instrument: quoteInstrument,
            quantity: parseFloat(quoteAmtStr),
            price: Math.abs(parseFloat(quotePriceStr)),
            isFee: false,
            transactionType: {
              id: SPOT_QUOTE_LEG_TYPE,
              name: 'Spot Quote Leg'
            },
            referenceInstrument: baseInstrument ?? undefined
          }
        ])
      }
      if (
        feeAmtStr !== '' &&
        feeInstrument !== null
      ) {
        const assetChangeId = uuidv4()
        assetChanges = assetChanges.concat([
          {
            id: `new-${assetChangeId}`,
            time: time.format('YYYY-MM-DD HH:mm'),
            settledTime: time.format('YYYY-MM-DD HH:mm'),
            account,
            strategy: strategy ?? undefined,
            desk: desk ?? undefined,
            instrument: feeInstrument,
            quantity: -1 * Math.abs(parseFloat(feeAmtStr)),
            price: 0,
            isFee: true,
            transactionType: {
              id: COMMISSION_LEG_TYPE,
              name: 'Trading Commission'
            },
            referenceInstrument: baseInstrument ?? undefined
          }
        ])
      }
      const txnId = uuidv4()
      const txn: TransactionCreation = {
        id: `new-${txnId}`,
        trader: trader ?? undefined,
        expectedSettlementTime: undefined,
        comments,
        entryPlatform: 'Frontend',
        entryPlatformId: `FE-${txnId}`,
        assetChanges,
        openedLiabilities: [],
        closedLiabilities: []
      }
      submit(txn)
    }
  }

  return (
    <Stack spacing={1}>
      <Grid spacing={1} container sx={{ width: '100%' }}>
        <Grid item xs={6}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Box sx={{ width: '50%' }}>
              <Typography
                sx={{ color: palette.tableBodyText.main }}
              >
                Time
              </Typography>
            </Box>
            <Box sx={{ width: '50%' }}>
              <DateTimePicker
                value={time}
                format='YYYY-MM-DD HH:mm'
                onChange={handleChangeTime}
                closeOnSelect={false}
                ampm={false}
                timeSteps={{ hours: 1, minutes: 1 }}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <TraderInput
            setTrader={setTrader}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            enhanced
          />
        </Grid>
        <Grid item xs={6}>
          <StrategyInput
            setStrategy={setStrategy}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            enhanced
            includeSuperStrategyFilter
          />
        </Grid>
        <Grid item xs={6}>
          <DeskInput
            setDesk={setDesk}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            enhanced
          />
        </Grid>
        <Grid item xs={6}>
          <StringInput
            value={comments}
            setValue={setComments}
            title='Comments'
            color={palette.accent.main}
          />
        </Grid>
        <Grid item xs={6}>
          <AccountInput
            setAccount={setAccount}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            enhanced
          />
        </Grid>
        <Grid item xs={12}>
          <LegInput
            legName='Base'
            amountStr={baseAmtStr}
            setAmountStr={setBaseAmtStr}
            priceStr={basePriceStr}
            setPriceStr={setBasePriceStr}
            setInstrument={setBaseInstrument}
          />
        </Grid>
        <Grid item xs={12}>
          <LegInput
            legName='Quote'
            amountStr={quoteAmtStr}
            setAmountStr={setQuoteAmtStr}
            priceStr={quotePriceStr}
            setPriceStr={setQuotePriceStr}
            setInstrument={setQuoteInstrument}
          />
        </Grid>
        <Grid item xs={12}>
          <LegInput
            legName='Commission'
            amountStr={feeAmtStr}
            setAmountStr={setFeeAmtStr}
            setInstrument={setFeeInstrument}
          />
        </Grid>
      </Grid>
      <Button
        onClick={handleSubmission}
        variant='contained'
        style={{
          width: '100%',
          backgroundColor: palette.accent.main,
          color: palette.secondary.main
        }}
      >
        Submit
      </Button>
    </Stack>
  )
}

export default SpotTrade
