import React from 'react'
import { MenuItem, Select, type SelectChangeEvent } from '@mui/material'
import {
  usePermissions as useAlertsPermission,
  type PermissionsContextType as AlertsPermissions
} from '@r40cap/alerts-sdk'

import { AlertsType, type AlertsTypeOption } from './types'
import { alertsTypeOptions } from './constants'

export function userCanAccess (viewOption: AlertsTypeOption, alertsPermissions: AlertsPermissions): boolean {
  switch (viewOption.type) {
    case AlertsType.MARGIN:
      return alertsPermissions.hasMarginAlertsAccess ?? false
    default:
      return alertsPermissions.hasMarginAlertsAccess ?? false
  }
}

function AlertsTypeInput (props: {
  currentType: AlertsType
  typeChangeFunction: (view: AlertsType) => void
}): React.JSX.Element {
  const { currentType, typeChangeFunction } = props
  const alertsPermissions = useAlertsPermission()

  const handleTypeChange = (event: SelectChangeEvent): void => {
    const newType: AlertsType = event.target.value as AlertsType
    typeChangeFunction(newType)
  }

  return (
    <Select
      value={currentType}
      onChange={handleTypeChange}
      sx={{ width: '100%', height: '100%' }}
      color="accent"
    >
      {alertsTypeOptions.filter((opt) => userCanAccess(opt, alertsPermissions)).map(
        (option) => (
          <MenuItem key={option.display} value={option.type}>
            {option.display}
          </MenuItem>
        ))}
    </Select>
  )
}

export default AlertsTypeInput
