import { type ColumnDefinition } from '@r40cap/ui'

import { type AlgoPositionRow } from './types'

export const algoPositionColumns: Array<ColumnDefinition<AlgoPositionRow, any>> = [
  {
    id: 'rowName',
    label: 'Name',
    columnWidthPercentage: 15,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'account',
    label: 'Account',
    columnWidthPercentage: 20,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'multiplier',
    label: 'Mult',
    columnWidthPercentage: 5,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    id: 'netQuantity',
    label: 'Net',
    columnWidthPercentage: 25,
    overflowType: 'wrap',
    sortable: true,
    colored: true,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals',
    signOverrideId: 'colorSign'
  },
  {
    id: 'grossQuantity',
    label: 'Gross',
    columnWidthPercentage: 28,
    overflowType: 'wrap',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimalsId: 'quantityDecimals'
  }
]
