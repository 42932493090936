import React, { useMemo, useState, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import type { Dayjs } from 'dayjs'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import {
  HeaderCell,
  type Order,
  ValueCell,
  stableSort,
  getComparator,
  TableSkeleton,
  PaginationFooter
} from '@r40cap/ui'
import { blotterApi } from '@r40cap/pms-sdk'

import { type CapitalCallRow } from '../common/types'
import { capitalCallColumns, ROWS_PER_PAGE } from './constants'
import type { PaginationState } from '../../common/types'

interface CapitalCallsTableHeadProps {
  onRequestSort: (property: keyof CapitalCallRow) => void
  order: Order
  orderBy: keyof CapitalCallRow
}

function CapitalCallsTableHead (props: CapitalCallsTableHeadProps): React.JSX.Element {
  const {
    onRequestSort,
    order,
    orderBy
  } = props
  const { palette } = useTheme()

  return (
    <TableHead>
      <TableRow>
        {capitalCallColumns.map((column, idx) => (
          <HeaderCell<CapitalCallRow, any>
            column={column}
            isActiveSort={orderBy === column.id}
            order={order}
            sortHandler={onRequestSort}
            key={column.id}
            backgroundColor={palette.background.default}
            textColor={palette.tableHeaderText.main}
            activeTextColor={palette.accent.main}
          />
        ))}
        <TableCell/>
      </TableRow>
    </TableHead>
  )
}

function CapitalCallsTableBody (props: {
  rows: CapitalCallRow[]
  paginationState: PaginationState
}): React.JSX.Element {
  const navigate = useNavigate()
  const { palette } = useTheme()
  const { rows, paginationState } = props

  const visibleRows = rows.slice(
    (paginationState.pageNumber - 1) * paginationState.pageSize,
    paginationState.pageNumber * paginationState.pageSize
  )

  return <TableBody>
    {
      visibleRows.map((row, index) => {
        return (
          <TableRow
            key={row.id}
            sx={{
              cursor: 'pointer',
              backgroundColor:
                index % 2 === 0
                  ? palette.primary.main
                  : palette.tertiary.main
            }}
          >
            {capitalCallColumns.map((column, idx) => (
              <ValueCell<CapitalCallRow, any>
                column={column}
                item={row}
                defaultTextColor={palette.tableBodyText.main}
                redTextColor='red'
                greenTextColor='green'
                key={column.id}
              />
            ))}
            <TableCell align='center'>
              <Button
                variant='outlined'
                onClick={() => { navigate(`/blotter/capitalCalls/${row.id}`) }}
                sx={{
                  outlineColor: palette.accent.main,
                  color: palette.accent.main,
                  width: '50%',
                  alignSelf: 'center'
                }}
              >
                Details
              </Button>
            </TableCell>
          </TableRow>
        )
      })
    }
    {
      visibleRows.length === 0
        ? <TableRow sx={{ height: '100%' }}>
            <TableCell
              colSpan={capitalCallColumns.length + 1}
              sx={{ justifyItems: 'center', alignItems: 'center' }}
            >
              <Typography textAlign={'center'}>No Data</Typography>
            </TableCell>
          </TableRow>
        : <TableRow sx={{ height: '100%' }} />
    }
  </TableBody>
}

function CapitalCallsTable (props: {
  time: Dayjs | null
  refreshSignal: boolean
}): React.JSX.Element {
  const { time, refreshSignal } = props
  const { palette } = useTheme()
  const [rows, setRows] = useState<CapitalCallRow[]>([])
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof CapitalCallRow>('time')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const { data, refetch, isFetching } = blotterApi.useGetRecentCapitalCallsQuery({
    time: time?.format('YYYY-MM-DD HH:mm') ?? undefined
  })

  useEffect(() => {
    refetch().catch((error) => { console.error(error) })
  }, [refreshSignal])

  useEffect(() => {
    if (data !== null && data !== undefined) {
      setRows(data.data.map((capitalCall) => {
        return {
          id: capitalCall.id,
          time: capitalCall.time,
          investor: capitalCall.investor.name,
          memo: capitalCall.memo
        }
      }))
    }
  }, [data])

  const visibleRows = useMemo(
    () => stableSort(rows, getComparator(order, orderBy)),
    [order, orderBy, rows]
  )

  const handleRequestSort = (property: keyof CapitalCallRow): void => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  function pageBackward (): void {
    setCurrentPage(Math.max(currentPage - 1, 1))
  }

  function skipBackward (): void {
    setCurrentPage(1)
  }

  function pageForward (): void {
    const maxPages = Math.ceil(rows.length / (ROWS_PER_PAGE ?? 10))
    setCurrentPage(Math.min(currentPage + 1, maxPages))
  }

  function skipForward (): void {
    const maxPages = Math.ceil(rows.length / (ROWS_PER_PAGE ?? 10))
    setCurrentPage(maxPages)
  }

  useEffect(() => {
    setCurrentPage(1)
  }, [rows.length])

  return (
    <>
      <TableContainer
        sx={{
          backgroundColor: palette.primary.main,
          borderRadius: '5px',
          height: '100%'
        }}
      >
        <Table
          stickyHeader
          sx={{
            tableLayout: 'fixed',
            height: '100%'
          }}
        >
          <CapitalCallsTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          {
            isFetching as boolean
              ? <TableSkeleton
                  numRows={10}
                  columns={capitalCallColumns}
                />
              : <CapitalCallsTableBody
                  rows={visibleRows}
                  paginationState={{ pageNumber: currentPage, pageSize: ROWS_PER_PAGE }}
                />
          }
          {
            !isFetching && <PaginationFooter
              numRows={rows.length}
              currentPage={currentPage}
              rowsPerPage={ROWS_PER_PAGE}
              onPageBackward={pageBackward}
              onSkipBackward={skipBackward}
              onPageForward={pageForward}
              onSkipForward={skipForward}
              numColumns={1 + capitalCallColumns.length}
              backgroundColor={palette.background.default}
              textColor={palette.tableHeaderText.main}
              isDense
            />
          }
        </Table>
      </TableContainer>
      <Outlet />
    </>
  )
}

export default CapitalCallsTable
