import React, { useEffect, useState, useMemo } from 'react'
import dayjs, { type Dayjs } from 'dayjs'
import minMax from 'dayjs/plugin/minMax'
import utc from 'dayjs/plugin/utc'
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  useTheme
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'
import { type Order, stableSort, getComparator } from '@r40cap/ui'
import { type Price } from '@r40cap/pms-sdk'

import type { PositionObject, PositionRow, ColumnDefinitionWithColor } from '../types'
import { ValueCellWithColor } from './cells'

function BaseFxRowWithPriority (props: {
  bottomRows: PositionObject[]
  referencePrice?: Price
  priority: number
  strategyColor: string
  includeSmallAmounts: boolean
  smallThreshold: number
  order: Order
  orderBy: keyof PositionRow
  columns: Array<ColumnDefinitionWithColor<PositionRow, any>>
  requestedTime?: Dayjs
  isOpen: boolean
  toggleOpen: () => void
}): React.JSX.Element {
  dayjs.extend(minMax)
  dayjs.extend(utc)
  const { palette } = useTheme()
  const {
    bottomRows,
    priority,
    columns,
    order,
    orderBy,
    strategyColor,
    referencePrice,
    includeSmallAmounts,
    smallThreshold,
    requestedTime,
    isOpen,
    toggleOpen
  } = props
  const [instruments, setInstruments] = useState<string []>(bottomRows.map((row) => row.instrumentId))

  useEffect(() => {
    setInstruments(bottomRows.map((row) => row.instrumentId))
  }, [bottomRows])

  const baseFxRowObject: PositionRow = {
    name: bottomRows.length > 0 ? bottomRows[0].baseFxName : '',
    priorityLevel: undefined,
    strategyColor,
    marketValue: bottomRows.reduce((partialSum, a) => partialSum + a.multiplier * (a.price ?? 0) * a.quantity, 0) ?? 0,
    referenceMarketValue: (referencePrice !== undefined)
      ? bottomRows.reduce((partialSum, a) => partialSum + a.multiplier * (a.price ?? 0) * a.quantity, 0) / referencePrice.price
      : 0,
    deltaQuantity: bottomRows.reduce((sum, current) => sum + (current.delta ?? 1) * current.quantity * current.multiplier, 0),
    priceDecimals: 0,
    quantityDecimals: bottomRows.length === 0 ? 2 : Math.max(...bottomRows.map(row => row.quantityDecimals)),
    requestedTime,
    receivedTime: dayjs.min(bottomRows.map((row) => dayjs.utc(row.priceTime))) ?? dayjs().utc()
  }

  const visibleInstruments = useMemo(
    () => {
      const filteredInstruments = includeSmallAmounts
        ? instruments
        : instruments.filter((inst) => {
          const instRows = bottomRows.filter((val) => val.instrumentId === inst)
          const absMv = instRows.reduce((sum, current) => sum + Math.abs(current.multiplier * (current.price ?? 0) * current.quantity), 0)
          return absMv >= smallThreshold
        })
      return stableSort(filteredInstruments, (a, b) => {
        const instARows = bottomRows.filter((val) => val.baseFxId === a)
        const psnRowA: PositionRow = {
          name: bottomRows.find((val) => val.baseFxId === a)?.baseFxName ?? '',
          priorityLevel: priority,
          strategyColor,
          marketValue: instARows.reduce((sum, current) => sum + current.multiplier * (current.price ?? 0) * current.quantity, 0),
          referenceMarketValue: referencePrice !== undefined
            ? instARows.reduce((sum, current) => sum + (current.price ?? 0) * current.multiplier * current.quantity, 0) / referencePrice.price
            : instARows.reduce((sum, current) => sum + (current.price ?? 0) * current.multiplier * current.quantity, 0),
          priceDecimals: 0,
          quantityDecimals: 0
        }
        const instBRows = bottomRows.filter((val) => val.baseFxId === b)
        const psnRowB: PositionRow = {
          name: bottomRows.find((val) => val.baseFxId === a)?.baseFxName ?? '',
          priorityLevel: priority,
          strategyColor,
          marketValue: instBRows.reduce((sum, current) => sum + current.multiplier * (current.price ?? 0) * current.quantity, 0),
          referenceMarketValue: referencePrice !== undefined
            ? instBRows.reduce((sum, current) => sum + (current.price ?? 0) * current.multiplier * current.quantity, 0) / referencePrice.price
            : instBRows.reduce((sum, current) => sum + (current.price ?? 0) * current.multiplier * current.quantity, 0),
          priceDecimals: 0,
          quantityDecimals: 0
        }
        return getComparator(order, orderBy)({ ...psnRowA, requestedTime: undefined, receivedTime: undefined }, { ...psnRowB, requestedTime: undefined, receivedTime: undefined })
      })
    },
    [order, orderBy, bottomRows, includeSmallAmounts, smallThreshold]
  )

  return (
    <>
      <TableRow sx={{ backgroundColor: palette.tertiary.main }}>
        <TableCell sx={{ padding: 1 }}>
          <Box alignItems={'center'}>
            <IconButton
              aria-label="expand row"
              size="small"
              disabled
            >
              <SubdirectoryArrowRightIcon fontSize='small'/>
            </IconButton>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={toggleOpen}
            >
              {
                isOpen
                  ? <KeyboardArrowUpIcon fontSize='small'/>
                  : <KeyboardArrowDownIcon fontSize='small'/>
              }
            </IconButton>
          </Box>
        </TableCell>
        {
          columns.map((column, idx) => (
            <ValueCellWithColor<PositionRow, any>
              column={column}
              item={baseFxRowObject}
              key={column.id}
              defaultTextColor={palette.tableBodyText.main}
              redTextColor='red'
              greenTextColor='green'
              dense
            />
          ))
        }
      </TableRow>
      {
        isOpen && visibleInstruments.map((inst) => {
          const obj = bottomRows.find((row) => row.instrumentId === inst)
          const rowObject: PositionRow = {
            name: obj !== undefined ? `\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0${obj.instrumentName}` : '',
            quantity: obj?.quantity,
            priorityLevel: undefined,
            strategyColor,
            multiplier: obj?.multiplier,
            price: obj?.price,
            delta: obj?.delta,
            beta: obj?.beta,
            marketValue: obj === undefined ? 0 : (obj.price ?? 0) * obj.quantity * obj.multiplier,
            referenceMarketValue: (obj === undefined || referencePrice === undefined) ? 0 : (obj.beta ?? 1) * (obj.price ?? 0) * obj.quantity * obj.multiplier / referencePrice.price,
            deltaQuantity: obj === undefined ? 0 : (obj.delta ?? 1) * obj.quantity * obj.multiplier,
            priceDecimals: obj?.priceDecimals ?? 0,
            quantityDecimals: obj?.quantityDecimals ?? 0,
            requestedTime,
            receivedTime: obj?.priceTime === undefined ? dayjs().utc() : dayjs.utc(obj.priceTime)
          }
          return (
            <TableRow
              key={inst}
              sx={{ backgroundColor: palette.secondary.main }}
            >
              <TableCell sx={{ padding: 1 }}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  disabled
                >
                  <SubdirectoryArrowRightIcon fontSize='small'/>
                </IconButton>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  disabled
                >
                  <SubdirectoryArrowRightIcon fontSize='small'/>
                </IconButton>
              </TableCell>
              {
                columns.map((column, columnIndex) => {
                  return (
                    <ValueCellWithColor
                      key={columnIndex}
                      column={column}
                      item={rowObject}
                      defaultTextColor={palette.tableBodyText.main}
                      redTextColor='red'
                      greenTextColor='green'
                      dense
                    />
                  )
                })
              }
            </TableRow>
          )
        })
      }
    </>
  )
}

export default BaseFxRowWithPriority
