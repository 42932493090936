import React from 'react'
import { MenuItem, Select, type SelectChangeEvent } from '@mui/material'
import { type AuthContextType, useAuth } from '@r40cap/auth'

import { PortfolioViewType, type PortfolioViewOption } from './types'
import { portfolioViewOptions } from './constants'

export function userCanAccess (viewOption: PortfolioViewOption, authConext: AuthContextType): boolean {
  switch (viewOption.type) {
    case PortfolioViewType.POSITIONS:
      return authConext.hasPositionAccess
    case PortfolioViewType.BALANCES:
      return authConext.hasBalanceAccess
    case PortfolioViewType.LIABILITIES:
      return authConext.hasLiabilityAccess
    default:
      return authConext.hasBalanceAccess
  }
}

function ViewSelection (props: {
  currentView: PortfolioViewType
  viewChangeFunction: (view: PortfolioViewType) => void
}): React.JSX.Element {
  const { currentView, viewChangeFunction } = props
  const authConext = useAuth()

  const handleTypeChange = (event: SelectChangeEvent): void => {
    const newType: PortfolioViewType = event.target.value as PortfolioViewType
    viewChangeFunction(newType)
  }

  return (
    <Select
      value={currentView}
      onChange={handleTypeChange}
      sx={{ width: '100%', height: '100%' }}
      color="accent"
    >
      {portfolioViewOptions.filter((opt) => userCanAccess(opt, authConext)).map(
        (option, index) => (
          <MenuItem key={option.type} value={option.type}>
            {option.display}
          </MenuItem>
        ))}
    </Select>
  )
}

export default ViewSelection
