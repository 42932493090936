import { type DeskOption } from './types'

export const deskOptions: DeskOption[] = [
  {
    deskIds: [
      'a4c29c1f-7c91-4258-ab1c-652c1089078d',
      '605816cd-26ca-48e2-ab34-a393276c438a'
    ],
    optionId: 'global+inv',
    label: 'Global + Investments'
  },
  {
    deskIds: ['a4c29c1f-7c91-4258-ab1c-652c1089078d'],
    optionId: 'global',
    label: 'Global'
  },
  {
    deskIds: ['605816cd-26ca-48e2-ab34-a393276c438a'],
    optionId: 'inv',
    label: 'Investments'
  },
  {
    deskIds: ['209b1408-beab-4d3a-b7ef-476040d7d6ff'],
    optionId: 'bd',
    label: 'BD'
  },
  {
    label: 'All',
    optionId: 'all',
    deskIds: undefined
  }
]
