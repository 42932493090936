import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Box, Stack, useTheme } from '@mui/material'
import { type ColumnDefinition, RefreshButton, Modal, useRequestSnackbar } from '@r40cap/ui'
import {
  type AssetChange,
  AssetChangeCreator,
  type Liability,
  type Transaction,
  type Price,
  blotterApi,
  priceApi
} from '@r40cap/pms-sdk'

import {
  txnDetailsAssetChangeColumns,
  closedLiabilityColumns,
  openedLiabilityColumns
} from './constants'
import type { AssetChangeRow, BlotterBigModal } from './types'
import type { BlotterInputType, LiabilityRow } from '../common/types'
import SmallLegTable from './SmallLegTable'
import TransactionPanel from './panels/TransactionPanel'
import { isApiError } from '../../../utils/errors'

function TransactionDetails (): React.JSX.Element {
  const { transactionId } = useParams()
  const { showSnackbar } = useRequestSnackbar()
  const navigate = useNavigate()
  const { palette } = useTheme()
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const [isForce, setIsForce] = useState<boolean>(false)
  const {
    data: assetChangesData,
    isFetching: isAssetChangesFetching,
    refetch: assetChangesRefetch
  } = blotterApi.useGetTransactionAssetChangesQuery({
    force: isForce,
    txnId: transactionId ?? ''
  }, {
    skip: isInitialLoad
  })
  const [addAssetChangesMutation] = blotterApi.useAddTransacitonAssetChangesMutation()
  const [editAssetChangesMutation] = blotterApi.useEditAssetChangesMutation()
  const [editLiabilitiesMutation] = blotterApi.useEditLiabilitiesMutation()
  const [postPrices] = priceApi.useUpdatePricesMutation()
  const {
    data: openedLiabilitiesData,
    isFetching: isOpenedLiabilitiesFetching,
    refetch: openedLiabilitiesRefetch
  } = blotterApi.useGetTransactionOpenedLiabilitiesQuery({
    force: isForce,
    txnId: transactionId ?? ''
  }, {
    skip: isInitialLoad
  })
  const {
    data: closedLiabilitiesData,
    isFetching: isClosedLiabilitiesFetching,
    refetch: closedLiabilitiesRefetch
  } = blotterApi.useGetTransactionClosedLiabilitiesQuery({
    force: isForce,
    txnId: transactionId ?? ''
  }, {
    skip: isInitialLoad
  })
  const {
    data: txnData,
    isFetching: isTxnFetching,
    refetch: txnRefetch
  } = blotterApi.useGetTransactionByIdQuery({
    force: isForce,
    txnId: transactionId ?? ''
  }, {
    skip: isInitialLoad
  })

  const [txn, setTxn] = useState<Transaction | null>(null)

  useEffect(() => {
    setTransactionResetSignal(!transactionResetSignal)
    if (txnData !== undefined && txnData !== null) {
      setTxn(txnData.data)
    } else {
      setTxn(null)
    }
  }, [txnData])

  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false)
    }
  }, [isInitialLoad])

  const [assetChangesSetDataSignal, setAssetChangesSetDataSignal] = useState<boolean>(false)
  const [openedLiabilitiesSetDataSignal, setOpenedLiabilitiesSetDataSignal] = useState<boolean>(false)
  const [closedLiabilitiesSetDataSignal, setClosedLiabilitiesSetDataSignal] = useState<boolean>(false)
  const [transactionResetSignal, setTransactionResetSignal] = useState<boolean>(false)

  function refreshAssetChangesFunction (): void {
    assetChangesRefetch().then(() => {
      setAssetChangesSetDataSignal(!assetChangesSetDataSignal)
    }).catch(() => {
      console.error('Error Refreshing Asset Changes')
    })
  }

  function pushAssetChangesFunction (additions: AssetChange[], edits: AssetChange[]): void {
    const addnPrices = additions.filter((assCh) => !(assCh.isFee as boolean) && assCh.price !== 0).map((assCh) => {
      const price: Price = {
        id: 'dummyId',
        time: assCh.time,
        instrument: assCh.instrument,
        price: assCh.price,
        delta: 1.0,
        beta: 1.0
      }
      return price
    })
    const editPrices = edits.filter((assCh) => (!(assCh.isFee as boolean) && assCh.price !== 0)).map((assCh) => {
      const price: Price = {
        id: 'dummyId',
        time: assCh.time,
        instrument: assCh.instrument,
        price: assCh.price,
        delta: 1.0,
        beta: 1.0
      }
      return price
    })
    let didWork = false
    let didFail = false
    const allPrices = addnPrices.concat(editPrices).filter((price) => price.instrument.pricingMethodName === 'Manual')
    if (additions.length > 0) {
      didWork = true
      showSnackbar({
        isOpen: true,
        message: 'Pushing Asset Change Additions',
        status: 'processing'
      })
      addAssetChangesMutation({ additions, txnId: transactionId ?? '' })
        .then((value) => {
          if (isApiError(value.error)) {
            console.error(value.error.data)
            didFail = true
            const msg = value.error.originalStatus === 400
              ? value.error.data
              : 'Unexpected Error, check logs'
            const msgFinal = `Error Adding Asset Changes: ${msg}`
            showSnackbar({
              isOpen: true,
              message: msgFinal,
              status: 'error'
            })
          }
        })
        .catch((error) => {
          console.error(error)
          didFail = true
          showSnackbar({
            isOpen: true,
            message: 'Failed to add Asset Changes',
            status: 'error'
          })
        })
    }
    if (!didFail && edits.length > 0) {
      didWork = true
      showSnackbar({
        isOpen: true,
        message: 'Pushing Asset Change Edits',
        status: 'processing'
      })
      editAssetChangesMutation({ edits })
        .then((value) => {
          if (isApiError(value.error)) {
            console.error(value.error.data)
            didFail = true
            const msg = value.error.originalStatus === 400
              ? value.error.data
              : 'Unexpected Error, check logs'
            const msgFinal = `Error Editing Asset Changes: ${msg}`
            showSnackbar({
              isOpen: true,
              message: msgFinal,
              status: 'error'
            })
          }
        })
        .catch((error) => {
          console.error(error)
          didFail = true
          showSnackbar({
            isOpen: true,
            message: 'Failed to edit Asset Changes',
            status: 'error'
          })
        })
    }
    if (!didFail && allPrices.length > 0) {
      didWork = true
      showSnackbar({
        isOpen: true,
        message: 'Pushing Prices',
        status: 'processing'
      })
      postPrices({ updates: allPrices })
        .then((value) => {
          if (isApiError(value.error)) {
            console.error(value.error.data)
            didFail = true
            const msg = value.error.originalStatus === 400
              ? value.error.data
              : 'Unexpected Error, check logs'
            const msgFinal = `Error Updating Prices: ${msg}`
            showSnackbar({
              isOpen: true,
              message: msgFinal,
              status: 'error'
            })
          }
        })
        .catch((error) => {
          console.error(error)
          didFail = true
          showSnackbar({
            isOpen: true,
            message: 'Failed to update prices',
            status: 'error'
          })
        })
    }
    if (didWork && !didFail) {
      showSnackbar({
        isOpen: true,
        message: 'Done',
        status: 'success'
      })
    }
  }

  function refreshOpenedLiabilitiesFunction (): void {
    openedLiabilitiesRefetch().then(() => {
      setOpenedLiabilitiesSetDataSignal(!openedLiabilitiesSetDataSignal)
    }).catch(() => {
      console.error('Error Refreshing Opened Liabilities')
    })
  }

  function pushLiabilities (additions: Liability[], edits: Liability[]): void {
    if (edits.length > 0) {
      showSnackbar({
        isOpen: true,
        message: 'Editing Liabilities',
        status: 'processing'
      })
      editLiabilitiesMutation({ edits })
        .then((value) => {
          if (isApiError(value.error)) {
            console.error(value.error.data)
            const msg = value.error.originalStatus === 400
              ? value.error.data
              : 'Unexpected Error, check logs'
            showSnackbar({
              isOpen: true,
              message: msg,
              status: 'error'
            })
          } else {
            showSnackbar({
              isOpen: true,
              message: 'Liabilities Editied',
              status: 'success'
            })
          }
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Error Editing Liabilities',
            status: 'error'
          })
        })
    }
  }

  function refreshClosedLiabilitiesFunction (): void {
    closedLiabilitiesRefetch().then(() => {
      setClosedLiabilitiesSetDataSignal(!closedLiabilitiesSetDataSignal)
    }).catch(() => {
      console.error('Error Refreshing Closed Liabilities')
    })
  }

  function refreshTransactionFunction (): void {
    txnRefetch().then(() => {
      setTransactionResetSignal(!transactionResetSignal)
    }).catch(() => {
      console.error('Error Refreshing Transaction')
    })
  }

  function refreshFunction (): void {
    if (!isInitialLoad) {
      if (!isForce) {
        setIsForce(true)
      } else {
        refreshAssetChangesFunction()
        refreshOpenedLiabilitiesFunction()
        refreshClosedLiabilitiesFunction()
        refreshTransactionFunction()
      }
    }
  }

  function openLinkedModalInner (relevantId: string, modalType: BlotterBigModal): void {
    if (modalType === 'expense') {
      navigate(`/blotter/expenses/${relevantId}`)
    } else if (modalType === 'loan') {
      navigate(`/blotter/loans/${relevantId}`)
    } else if (modalType === 'capitalCall') {
      navigate(`/blotter/capitalCalls/${relevantId}`)
    }
  }

  function exitModal (): void {
    if ((Boolean(window.history.state)) && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate('/blotter/transactions')
    }
  }

  return (
    <Modal
      open
      handleClose={exitModal}
    >
      <Box
        sx={{
          width: '95vw',
          height: '95vh',
          overflow: 'scroll'
        }}
      >
        <Stack
          direction={'column'}
          padding={'40px'}
          spacing={5}
        >
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <RefreshButton
              refreshFunction={refreshFunction}
              iconColor={palette.primary.main}
              buttonColor={palette.accent.main}
            />
          </Box>
          <TransactionPanel transaction={txn} isFetching={isTxnFetching} resetSignal={transactionResetSignal}/>
          <SmallLegTable<AssetChangeRow, AssetChange>
            title='Asset Changes'
            columns={txnDetailsAssetChangeColumns}
            data={assetChangesData}
            isFetching={isAssetChangesFetching}
            translationFunciton={(change) => {
              return {
                ...change,
                account: `${change.account.platformName}: ${change.account.name}`,
                strategy: (
                  change.strategy === undefined || change.strategy === null ? '' : `${change.strategy.superStrategyName}: ${change.strategy.name}`
                ),
                desk: change.desk?.name ?? '',
                instrument: change.instrument.displayTicker,
                quantityDecimals: change.instrument.quantityDecimals,
                priceDecimals: change.instrument.priceDecimals,
                time: change.time,
                settledTime: change.settledTime ?? '',
                transactionId: change.associatedTransaction.id,
                expenseId: change.associatedExpense?.id ?? '',
                expenseReason: change.associatedExpense?.reason ?? '',
                capitalCallId: change.associatedCapitalCall?.id ?? '',
                investor: change.associatedCapitalCall?.investor.name ?? '',
                transactionType: change.transactionType?.name ?? '',
                referenceInstrument: change.referenceInstrument?.displayTicker ?? '',
                referencePrice: (change.referencePrice ?? undefined) === undefined ? '' : `${change.referencePrice}`
              }
            }}
            openLinkedModal={
              (
                column: ColumnDefinition<AssetChangeRow, BlotterInputType, AssetChange, string, BlotterBigModal>,
                row: AssetChangeRow
              ) => {
                if (column.modalId !== undefined && column.modalType !== undefined) {
                  openLinkedModalInner(row[column.modalId], column.modalType)
                }
              }
            }
            setDataSignal={assetChangesSetDataSignal}
            getAddModalContents={(submitFunction: (change: AssetChange) => void) => {
              return <Box
                sx={{ width: '40vw', padding: '40px' }}
              >
                {
                  txn !== null && <AssetChangeCreator
                    mainColor={palette.accent.main}
                    secondaryColor={palette.tableBodyText.main}
                    callColor={palette.success.main}
                    putColor={palette.error.main}
                    parentTransaction={txn}
                    submitFunction={submitFunction}
                    enhanced
                    includeBaseFxFilter
                    includeEntityFilter
                    includeInstrumentTypeFilter
                    includeSuperStrategyFilter
                  />
                }
              </Box>
            }}
            pushFunction={pushAssetChangesFunction}
          />
          <SmallLegTable<LiabilityRow, Liability>
            title='Opened Liabilities'
            columns={openedLiabilityColumns}
            data={openedLiabilitiesData}
            translationFunciton={(change) => {
              return {
                ...change,
                account: `${change.account.platform.name}: ${change.account.name}`,
                strategy: (
                  change.strategy === undefined || change.strategy === null ? '' : `${change.strategy.superStrategy.name}: ${change.strategy.name}`
                ),
                desk: change.desk?.name ?? '',
                instrument: change.instrument.displayTicker,
                quantityDecimals: change.instrument.quantityDecimals,
                priceDecimals: change.instrument.priceDecimals,
                openTime: change.openedTime,
                openTransactionId: change.openedTransaction.id,
                closeTime: change.closedTime ?? '',
                closeTransactionId: change.closedTransaction?.id ?? '',
                counterparty: change.associatedLoan.counterparty.name,
                topUpLevel: change.topUpLevel === undefined || change.topUpLevel === null ? '' : `${change.topUpLevel}`,
                interestRate: `${change.interestRate * 100}%`,
                loanId: change.associatedLoan.id
              }
            }}
            openLinkedModal={
              (
                column: ColumnDefinition<LiabilityRow, BlotterInputType, Liability, string, BlotterBigModal>,
                row: LiabilityRow
              ) => {
                if (column.modalId !== undefined && column.modalType !== undefined) {
                  openLinkedModalInner(row[column.modalId], column.modalType)
                }
              }
            }
            pushFunction={pushLiabilities}
            setDataSignal={openedLiabilitiesSetDataSignal}
            isFetching={isOpenedLiabilitiesFetching}
          />
          <SmallLegTable<LiabilityRow, Liability>
            title='Closed Liabilities'
            columns={closedLiabilityColumns}
            data={closedLiabilitiesData}
            translationFunciton={(change) => {
              return {
                ...change,
                account: `${change.account.platform.name}: ${change.account.name}`,
                strategy: (
                  change.strategy === undefined || change.strategy === null ? '' : `${change.strategy.superStrategy.name}: ${change.strategy.name}`
                ),
                desk: change.desk?.name ?? '',
                instrument: change.instrument.displayTicker,
                quantityDecimals: change.instrument.quantityDecimals,
                priceDecimals: change.instrument.priceDecimals,
                openTime: change.openedTime,
                openTransactionId: change.openedTransaction.id,
                closeTime: change.closedTime ?? '',
                closeTransactionId: change.closedTransaction?.id ?? '',
                counterparty: change.associatedLoan.counterparty.name,
                topUpLevel: change.topUpLevel === undefined || change.topUpLevel === null ? '' : `${change.topUpLevel}`,
                interestRate: `${change.interestRate * 100}%`,
                loanId: change.associatedLoan.id
              }
            }}
            openLinkedModal={
              (
                column: ColumnDefinition<LiabilityRow, BlotterInputType, Liability, string, BlotterBigModal>,
                row: LiabilityRow
              ) => {
                if (column.modalId !== undefined && column.modalType !== undefined) {
                  openLinkedModalInner(row[column.modalId], column.modalType)
                }
              }
            }
            pushFunction={pushLiabilities}
            setDataSignal={closedLiabilitiesSetDataSignal}
            isFetching={isClosedLiabilitiesFetching}
          />
        </Stack>
      </Box>
    </Modal>
  )
}

export default TransactionDetails
