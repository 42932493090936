import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Box, useTheme } from '@mui/material'
import {
  type ColumnDefinition,
  EditableTable,
  type EditableRowObject,
  type ObjectWithId
} from '@r40cap/ui'

import { GlossaryInputType } from './types'
import {
  BooleanEditContent,
  DeskxEditContent,
  IntegerEditContent,
  NumberEditContent,
  StringEditContent,
  DateTimeEditContent,
  SuperStrategyEditContent,
  EntityEditContent,
  PricingMethodEditContent,
  BaseFxEditContent,
  InstrumentTypeEditContent,
  ColorEditContent,
  SettlementInstrumentEditContent,
  TransactionSuperTypeEditContent
} from './modals'

function getModalContent<O> (
  inputType: GlossaryInputType,
  label: string,
  editProperty: keyof O,
  closeModal: () => void,
  handleSubmission: (value: any, selectedIds: readonly string[], property?: keyof O) => void,
  newSelected: readonly string[],
  setSelected: (values: string[]) => void,
  initialValue?: string | null
): React.JSX.Element {
  let content: React.JSX.Element = <></>

  function submit (value: any): void {
    handleSubmission(value, newSelected, editProperty)
    setSelected([])
  }

  switch (inputType) {
    case GlossaryInputType.DATETIME:
      content = <DateTimeEditContent
        closeModal={closeModal}
        submit={submit}
      />
      break
    case GlossaryInputType.SUPER_STRATEGY:
      content = <SuperStrategyEditContent
        closeModal={closeModal}
        submit={submit}
        prefillSuperStrategyId={initialValue}
      />
      break
    case GlossaryInputType.ENTITY:
      content = <EntityEditContent
        closeModal={closeModal}
        submit={submit}
        prefillEntityId={initialValue}
      />
      break
    case GlossaryInputType.COUNTERPARTY:
      content = <EntityEditContent
        closeModal={closeModal}
        submit={submit}
        prefillEntityId={initialValue}
        allowNull={true}
      />
      break
    case GlossaryInputType.PRICING_METHOD:
      content = <PricingMethodEditContent
        closeModal={closeModal}
        submit={submit}
        prefillPricingMethodId={initialValue}
      />
      break
    case GlossaryInputType.BASE_FX:
      content = <BaseFxEditContent
        closeModal={closeModal}
        submit={submit}
        prefillBaseFxId={initialValue}
      />
      break
    case GlossaryInputType.DESK:
      content = <DeskxEditContent
        closeModal={closeModal}
        submit={submit}
        prefillDeskId={initialValue}
      />
      break
    case GlossaryInputType.INSTRUMENT_TYPE:
      content = <InstrumentTypeEditContent
        closeModal={closeModal}
        submit={submit}
        prefillInstrumentTypeId={initialValue}
      />
      break
    case GlossaryInputType.COLOR:
      content = <ColorEditContent
        title={label}
        closeModal={closeModal}
        submit={submit}
        prefillValue={initialValue ?? undefined}
      />
      break
    case GlossaryInputType.TEXT:
      content = <StringEditContent
          title={label}
          closeModal={closeModal}
          submit={submit}
        />
      break
    case GlossaryInputType.BOOLEAN:
      content = <BooleanEditContent
          closeModal={closeModal}
          submit={submit}
          title={label}
        />
      break
    case GlossaryInputType.SETTLEMENT_INSTRUMENT:
      content = <SettlementInstrumentEditContent
          closeModal={closeModal}
          submit={submit}
          title={label}
        />
      break
    case GlossaryInputType.INTEGER:
      content = <IntegerEditContent
          closeModal={closeModal}
          submit={submit}
          title={label}
        />
      break
    case GlossaryInputType.TRANSACTION_SUPER_TYPE:
      content = <TransactionSuperTypeEditContent
          closeModal={closeModal}
          submit={submit}
        />
      break
    default:
      content = <NumberEditContent
          closeModal={closeModal}
          submit={submit}
          title={label}
        />
  }
  return content
}

function GlossaryTable<R extends ObjectWithId<string>, O> (props: {
  rows: ReadonlyArray<EditableRowObject<R, string>>
  columns: Array<ColumnDefinition<R, GlossaryInputType, O>>
  defaultOrderBy: keyof R
  getPreset: (rowId: string, property: keyof O) => any
  isFetching: boolean
  editSubmission: (property: keyof O, value: any, selectedIds: readonly string[]) => void
  clearSelectedSignal: boolean
}): React.JSX.Element {
  const {
    rows,
    columns,
    defaultOrderBy,
    getPreset,
    isFetching,
    editSubmission,
    clearSelectedSignal
  } = props
  const { palette } = useTheme()
  const [selected, setSelected] = useState<readonly string[]>([])

  function handleSubmission (value: any, selectedIds: readonly string[], property?: keyof O): void {
    if (property !== undefined) {
      editSubmission(property, value, selectedIds)
    }
  }

  useEffect(() => {
    setSelected([])
  }, [clearSelectedSignal])

  return (
    <Box sx={{ height: '100%' }}>
      <EditableTable<R, O, GlossaryInputType, string>
        rows={rows}
        columns={columns}
        selected={selected}
        defaultOrderBy={defaultOrderBy}
        defaultOrder='asc'
        setSelected={setSelected}
        isFetching={isFetching}
        getPreset={getPreset}
        getModalContent={(
          inputType: GlossaryInputType,
          label: string,
          editProperty: keyof O,
          closeModal: () => void,
          newSelected: readonly string[],
          initialValue: any
        ) => {
          return getModalContent<O>(
            inputType,
            label,
            editProperty,
            closeModal,
            handleSubmission,
            newSelected,
            setSelected,
            initialValue
          )
        }}
        checkboxColor={palette.accent.main}
        backgroundColor={palette.primary.main}
        headerBackgroundColor={palette.background.default}
        headerTextColor={palette.tableHeaderText.main}
        headerActiveTextColor={palette.accent.main}
        bodyDefaultTextColor={palette.tableBodyText.main}
        redTextColor='red'
        greenTextColor='green'
        bodyPrimaryBackgroundColor={palette.tertiary.main}
        rowsPerPage={15}
        footerBackgroundColor={palette.background.default}
        footerTextColor={palette.tableHeaderText.main}
      />
      <Outlet />
    </Box>
  )
}

export default GlossaryTable
