import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Stack, Typography, Tooltip, useTheme } from '@mui/material'
import { riskApi } from '@r40cap/pms-sdk'

interface RiskDisplayItem {
  description: string
  displayValue: string
}

const REFRESH_INTERVAL = 1000 * 10

function RiskComponentsBanner (): React.JSX.Element {
  const { accountId } = useParams()
  const { palette } = useTheme()
  const [displayItems, setDisplayItems] = useState<readonly RiskDisplayItem[]>([])

  const { data, refetch } = riskApi.useGetPlatformRiskQuery({ accountId: accountId ?? '' })

  useEffect(() => {
    if (data !== null && data !== undefined) {
      const items = data.data.components.map((component) => {
        const valueStr = component.value.toLocaleString('en-US', {
          minimumFractionDigits: component.decimals ?? 2,
          maximumFractionDigits: component.decimals ?? 2
        })
        return {
          description: component.description,
          displayValue: `${component.prefix ?? ''}${valueStr}${component.suffix ?? ''}`
        }
      })
      setDisplayItems(items)
    }
  }, [data])

  useEffect(() => {
    const interval = setInterval(() => {
      refetch()
        .catch((error) => {
          console.error('Error fetching platform risk:', error)
        })
    }, REFRESH_INTERVAL)
    return () => { clearInterval(interval) }
  }, [refetch])

  return (
    <Stack direction='row' justifyContent={'space-evenly'}>
      {
        displayItems.map((item, index) => (
          <Tooltip
            title={'Live Value (Not affected by inputs)'}
            placement='bottom'
            key={item.description}
            arrow
          >
            <Stack direction='column' alignItems={'center'}>
              <Typography sx={{ color: palette.tableBodyText.main }} variant='h5'>{item.displayValue}</Typography>
              <Typography variant='body2' sx={{ color: palette.accent.main }}>{item.description}</Typography>
            </Stack>
          </Tooltip>
        ))
      }
    </Stack>
  )
}

export default RiskComponentsBanner
