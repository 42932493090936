import React from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { AuthProvider } from '@r40cap/auth'
import { PermissionsProvider as AlertsPermissionsProvider } from '@r40cap/alerts-sdk'
import { PermissionsProvider as AlgoPermissionsProvider } from '@r40cap/algos-sdk'
import { RequestSnackbar, RequestSnackbarProvider } from '@r40cap/ui'

import themeOptions from './themes/theme'
import { getMode } from './features/theme/themeSlice'
import PageHandler from './PageHandler'

function App (): React.JSX.Element {
  const mode = useSelector(getMode)
  const theme = createTheme(themeOptions(mode))

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <RequestSnackbarProvider>
            <AuthProvider>
              <AlertsPermissionsProvider>
                <AlgoPermissionsProvider>
                  <PageHandler/>
                  <RequestSnackbar
                    loadingColor={theme.palette.accent.main}
                  />
                </AlgoPermissionsProvider>
              </AlertsPermissionsProvider>
            </AuthProvider>
          </RequestSnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
