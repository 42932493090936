import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useTheme
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { StringInput } from '@r40cap/ui'
import {
  type LiteTrader,
  TraderInput,
  type TransactionCreation,
  type LiteInstrument,
  type LiteAccount,
  type LiteStrategy,
  AccountInput,
  StrategyInput,
  type LiteDesk,
  DeskInput
} from '@r40cap/pms-sdk'

import LegInput from '../../common/BlotterInputs/LegInput'
import { STRATEGY_TRANSFER_TYPE } from './constants'

function StrategyTransfer (props: {
  submit: (transaction: TransactionCreation) => void
}): React.JSX.Element {
  const { submit } = props
  dayjs.extend(utc)
  const { palette } = useTheme()
  const [trader, setTrader] = useState<LiteTrader | null>(null)
  const [account, setAccount] = useState<LiteAccount | null>(null)
  const [recievingStrategy, setRecievingStrategy] = useState<LiteStrategy | null>(null)
  const [recievingDesk, setRecievingDesk] = useState<LiteDesk | null>(null)
  const [sendingStrategy, setSendingStrategy] = useState<LiteStrategy | null>(null)
  const [sendingDesk, setSendingDesk] = useState<LiteDesk | null>(null)
  const [time, setTime] = useState<Dayjs | null>(dayjs().utc())
  const [comments, setComments] = useState<string>('')

  // Recieved Leg
  const [recievedInstrument, setRecievedInstrument] = useState<LiteInstrument | null>(null)
  const [recievedAmtStr, setRecievedAmtStr] = useState<string>('')
  const [recievedPriceStr, setRecievedPriceStr] = useState<string>('')

  const handleChangeTime = (newTime: Dayjs | null): void => {
    if (newTime !== null) {
      setTime(newTime)
    }
  }

  function handleSubmission (): void {
    if (
      time !== null &&
      account !== null &&
      recievingStrategy !== null &&
      sendingStrategy !== null &&
      recievedAmtStr !== '' &&
      recievedInstrument !== null
    ) {
      const txnId = uuidv4()
      const changeId1 = uuidv4()
      const changeId2 = uuidv4()
      const txn: TransactionCreation = {
        id: `new-${txnId}`,
        trader: trader ?? undefined,
        expectedSettlementTime: undefined,
        comments,
        entryPlatform: 'Frontend',
        entryPlatformId: `FE-${txnId}`,
        assetChanges: [
          {
            id: `new-${changeId1}`,
            time: time.format('YYYY-MM-DD HH:mm'),
            settledTime: time.format('YYYY-MM-DD HH:mm'),
            account,
            strategy: recievingStrategy,
            desk: recievingDesk ?? undefined,
            instrument: recievedInstrument,
            quantity: Math.abs(parseFloat(recievedAmtStr)),
            price: Math.abs(parseFloat(recievedPriceStr)),
            isFee: false,
            transactionType: {
              id: STRATEGY_TRANSFER_TYPE,
              name: 'Strategy Transfer'
            }
          },
          {
            id: `new-${changeId2}`,
            time: time.format('YYYY-MM-DD HH:mm'),
            settledTime: time.format('YYYY-MM-DD HH:mm'),
            account,
            strategy: sendingStrategy,
            desk: sendingDesk ?? undefined,
            instrument: recievedInstrument,
            quantity: -1 * Math.abs(parseFloat(recievedAmtStr)),
            price: Math.abs(parseFloat(recievedPriceStr)),
            isFee: false,
            transactionType: {
              id: STRATEGY_TRANSFER_TYPE,
              name: 'Strategy Transfer'
            }
          }
        ],
        openedLiabilities: [],
        closedLiabilities: []
      }
      submit(txn)
    }
  }

  return (
    <Stack spacing={1}>
      <Grid spacing={1} container sx={{ width: '100%' }}>
        <Grid item xs={6}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Box sx={{ width: '50%' }}>
              <Typography
                sx={{ color: palette.tableBodyText.main }}
              >
                Time
              </Typography>
            </Box>
            <Box sx={{ width: '50%' }}>
              <DateTimePicker
                value={time}
                format='YYYY-MM-DD HH:mm'
                onChange={handleChangeTime}
                closeOnSelect={false}
                ampm={false}
                timeSteps={{ hours: 1, minutes: 1 }}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <TraderInput
            setTrader={setTrader}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            enhanced
          />
        </Grid>
        <Grid item xs={6}>
          <AccountInput
            setAccount={setAccount}
            setNone={(dummy: boolean) => {}}
            allowNone={false}
            mainColor={palette.accent.main}
            secondaryColor={palette.tableBodyText.main}
            includeEntityFilter
            enhanced
          />
        </Grid>
        <Grid item xs={6}>
          <StringInput
            value={comments}
            setValue={setComments}
            title='Comments'
            color={palette.accent.main}
          />
        </Grid>
        <Grid item xs={12}>
          <LegInput
            legName='Transfer'
            amountStr={recievedAmtStr}
            setAmountStr={setRecievedAmtStr}
            priceStr={recievedPriceStr}
            setPriceStr={setRecievedPriceStr}
            setInstrument={setRecievedInstrument}
          />
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={1} sx={{ width: '100%' }}>
            <Typography
              sx={{ color: palette.tableBodyText.main }}
            >
              From
            </Typography>
            <StrategyInput
              setStrategy={setSendingStrategy}
              setNone={(dummy: boolean) => {}}
              allowNone={false}
              mainColor={palette.accent.main}
              secondaryColor={palette.tableBodyText.main}
              includeSuperStrategyFilter
              enhanced
            />
            <DeskInput
              setDesk={setSendingDesk}
              setNone={(dummy: boolean) => {}}
              allowNone={false}
              mainColor={palette.accent.main}
              secondaryColor={palette.tableBodyText.main}
              enhanced
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={1} sx={{ width: '100%' }}>
            <Typography
              sx={{ color: palette.tableBodyText.main }}
            >
              To
            </Typography>
            <StrategyInput
              setStrategy={setRecievingStrategy}
              setNone={(dummy: boolean) => {}}
              allowNone={false}
              mainColor={palette.accent.main}
              secondaryColor={palette.tableBodyText.main}
              includeSuperStrategyFilter
              enhanced
            />
            <DeskInput
              setDesk={setRecievingDesk}
              setNone={(dummy: boolean) => {}}
              allowNone={false}
              mainColor={palette.accent.main}
              secondaryColor={palette.tableBodyText.main}
              enhanced
            />
          </Stack>
        </Grid>
      </Grid>
      <Button
        onClick={handleSubmission}
        variant='contained'
        style={{
          width: '100%',
          backgroundColor: palette.accent.main,
          color: palette.secondary.main
        }}
      >
        Submit
      </Button>
    </Stack>
  )
}

export default StrategyTransfer
