export type ExpenseAdditionOption = (
  'basicExpense' |
  'custom'
)

export interface ExpenseAdditionSelectOption {
  type: ExpenseAdditionOption
  display: string
  width: number
}

export type CapitalCallAdditionOption = (
  'basicCapitalCall' |
  'custom'
)

export interface CapitalCallAdditionSelectOption {
  type: CapitalCallAdditionOption
  display: string
  width: number
}

export interface LoanRow {
  id: string
  counterparty: string
  expiration: string
}

export interface ExpenseRow {
  id: string
  time: string
  vendor: string
  reason: string
}

export interface CapitalCallRow {
  id: string
  time: string
  investor: string
  memo: string
}

export interface TransactionRow {
  id: string
  time: string
  trader: string
  legOne: string
  legTwo: string
}

export interface AddAssetChangeRow {
  id: string
  time: string
  settledTime: string
  account: string
  strategy: string
  desk: string
  instrument: string
  quantity: number
  price: number
  isFee: boolean
  quantityDecimals: number
  priceDecimals: number
}

export interface LiabilityRow {
  id: string
  counterparty: string
  openTime: string
  closeTime: string
  account: string
  strategy: string
  desk: string
  instrument: string
  quantity: number
  price: number
  interestRate: string
  topUpLevel: string
  quantityDecimals: number
  priceDecimals: number
  openTransactionId: string
  closeTransactionId: string
  loanId: string
}

export interface AddLiabilityRow {
  id: string
  openTime: string
  account: string
  strategy: string
  desk: string
  instrument: string
  quantity: number
  price: number
  interestRate: string
  topUpLevel: string
  quantityDecimals: number
  priceDecimals: number
}

export enum BlotterInputType {
  NUMBER,
  BOOLEAN,
  TEXT,
  TIME,
  STRATEGY,
  INSTRUMENT,
  ACCOUNT,
  ENTITY,
  STRING,
  TRADER,
  INVESTOR,
  DESK,
  TRANSACTION_TYPE
}

export enum BlotterAdditionType {
  TRANSACTION,
  EXPENSE,
  LOAN,
  CAPITAL_CALL
}
