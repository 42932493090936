import type { LoanAdditionSelectOption, TransactionAdditionSelectOption } from './types'

export const loanSelectOptions: LoanAdditionSelectOption[] = [
  {
    type: 'basicLoan',
    display: 'Basic Loan',
    width: 90
  },
  {
    type: 'custom',
    display: 'Custom',
    width: 90
  }
]

export const transactionSelectOptions: TransactionAdditionSelectOption[] = [
  {
    type: 'spotTrade',
    display: 'Spot/Equity Trade',
    width: 80
  },
  {
    type: 'optionsTrade',
    display: 'Options Trade',
    width: 80
  },
  {
    type: 'perpsTrade',
    display: 'Perps Trade',
    width: 80
  },
  {
    type: 'futuresTrade',
    display: 'Futures Trade',
    width: 80
  },
  {
    type: 'strategyTransfer',
    display: 'Strategy Transfer',
    width: 80
  },
  {
    type: 'accountTransfer',
    display: 'Account Transfer',
    width: 80
  },
  {
    type: 'custom',
    display: 'Custom',
    width: 90
  }
]
