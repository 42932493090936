import React, { useState } from 'react'
import { Button, TableCell, TableHead, TableRow, useTheme } from '@mui/material'
import { HeaderCell, type Order } from '@r40cap/ui'

import type { PortfolioRow, PositionRow, ColumnDefinitionWithStaleness } from '../types'

function OneLevelTableHead (props: {
  onRequestSort: (property: keyof PortfolioRow) => void
  order: Order
  orderBy: keyof PositionRow
  columns: Array<ColumnDefinitionWithStaleness<PortfolioRow, any>>
  openAll: () => void
  closeAll: () => void
  openDesc: string
  isLoading: boolean
}): React.JSX.Element {
  const { palette } = useTheme()
  const {
    onRequestSort,
    order,
    orderBy,
    columns,
    openAll,
    closeAll,
    openDesc,
    isLoading
  } = props
  const [nextClickOpen, setNextClickOpen] = useState<boolean>(true)

  const createSortHandler = (property: keyof PortfolioRow): void => {
    onRequestSort(property)
  }

  function handleToggle (): void {
    if (nextClickOpen) {
      openAll()
      setNextClickOpen(false)
    } else {
      closeAll()
      setNextClickOpen(true)
    }
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ color: palette.tertiary.main, padding: 1 }}>
          <Button
            variant='outlined'
            disabled={isLoading}
            onClick={handleToggle}
            sx={{
              color: palette.accent.main,
              fontSize: 9
            }}
          >
            {`${nextClickOpen ? 'Open' : 'Close'} ${openDesc}`}
          </Button>
        </TableCell>
        {columns.map((column, idx) => (
          <HeaderCell<PortfolioRow, any>
            column={column}
            isActiveSort={orderBy === column.id}
            order={order}
            sortHandler={createSortHandler}
            key={column.id}
            backgroundColor={palette.background.default}
            textColor={palette.tableHeaderText.main}
            activeTextColor={palette.accent.main}
            dense
          />
        ))}
      </TableRow>
    </TableHead>
  )
}

export default OneLevelTableHead
