import React, { useState } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import { useAuth } from '@r40cap/auth'

import NoAccess from './NoAccess'
import Page from './Page'
import GlossaryControlsPanel from '../components/glossary/GlossaryControlsPanel'
import {
  AccountTable,
  BaseFxTable,
  DeskTable,
  EntityTable,
  InstrumentTable,
  InstrumentTypeTable,
  InvestorTable,
  PricingMethodTable,
  StrategyTable,
  SuperStrategyTable,
  TraderTable,
  TransactionSuperTypeTable,
  TransactionTypeTable
} from '../components/glossary/tables'
import {
  AddAccountModal,
  AddBaseFxModal,
  AddDeskModal,
  AddEntityModal,
  AddInstrumentModal,
  AddInstrumentTypeModal,
  AddInvestorModal,
  AddPricingMethodModal,
  AddStrategyModal,
  AddSuperStrategyModal,
  AddTraderModal,
  AddTransactionSuperTypeModal,
  AddTransactionTypeModal
} from '../components/glossary/additions'
import type { TableProps } from '../components/glossary/tables/types'
import type { GlossaryOption } from '../components/glossary/types'

const REDIRECT_TYPE = 'strategies' as GlossaryOption

function Glossary (): React.JSX.Element {
  const navigate = useNavigate()
  const location = useLocation()
  const authContext = useAuth()
  const [includeDeprecated, setIncludeDeprecated] = useState<boolean>(false)
  const [refreshSignal, setRefreshSignal] = useState<boolean>(false)
  const [pushSignal, setPushSignal] = useState<boolean>(false)
  const [showBaseTables, setShowBaseTables] = useState<boolean>(false)
  const [editedList, setEditedList] = useState<readonly string[]>([])

  const viewType = (location.pathname.split('/')[2] ?? REDIRECT_TYPE) as GlossaryOption

  function markEdited (ids: readonly string[]): void {
    const editedSet = new Set(editedList)
    ids.forEach((value) => { editedSet.add(value) })
    setEditedList(Array.from(editedSet))
  }

  const tableProps: TableProps = {
    refreshSignal,
    pushSignal,
    includeDeprecated,
    editedList,
    markEdited,
    clearEdited: () => { setEditedList([]) }
  }

  return (
    authContext.hasGlossaryAccess as boolean
      ? <Page>
          <Stack
            spacing={1}
            direction={'column'}
            width={'100%'}
            sx={{ paddingTop: '90px', paddingBottom: '10px', height: 'calc(100% - 90px - 10px)' }}
          >
            <Box sx={{ height: '7%' }}>
              <GlossaryControlsPanel
                currentType={viewType}
                changeType={(newType: GlossaryOption) => { navigate(`/glossary/${newType}`) }}
                stuffToPush={editedList.length > 0}
                refreshFunction={() => { setRefreshSignal(!refreshSignal) }}
                pushFunction={() => { setPushSignal(!pushSignal) }}
                includeDeprecated={includeDeprecated}
                setIncludeDeprecated={setIncludeDeprecated}
                showBaseTables={showBaseTables}
                setShowBaseTables={setShowBaseTables}
              />
            </Box>
            <Box sx={{ height: '93%' }}>
              <Routes>
                <Route path='' element={<Navigate to={REDIRECT_TYPE} replace />} />
                <Route path={'accounts'} element={<AccountTable {...tableProps}/>}>
                  <Route path={'new'} element={<AddAccountModal />} />
                </Route>
                <Route path={'baseFxs'} element={<BaseFxTable {...tableProps}/>}>
                  <Route path={'new'} element={<AddBaseFxModal />} />
                </Route>
                <Route path={'desks'} element={<DeskTable {...tableProps}/>}>
                  <Route path={'new'} element={<AddDeskModal />} />
                </Route>
                <Route path={'entities'} element={<EntityTable {...tableProps}/>}>
                  <Route path={'new'} element={<AddEntityModal />} />
                </Route>
                <Route path={'instruments'} element={<InstrumentTable {...tableProps}/>}>
                  <Route path={'new'} element={<AddInstrumentModal />} />
                </Route>
                <Route path={'instrumentTypes'} element={<InstrumentTypeTable {...tableProps}/>}>
                  <Route path={'new'} element={<AddInstrumentTypeModal />} />
                </Route>
                <Route path={'investors'} element={<InvestorTable {...tableProps}/>}>
                  <Route path={'new'} element={<AddInvestorModal />} />
                </Route>
                <Route path={'pricingMethods'} element={<PricingMethodTable {...tableProps}/>}>
                  <Route path={'new'} element={<AddPricingMethodModal />} />
                </Route>
                <Route path={'strategies'} element={<StrategyTable {...tableProps}/>}>
                  <Route path={'new'} element={<AddStrategyModal />} />
                </Route>
                <Route path={'superStrategies'} element={<SuperStrategyTable {...tableProps}/>}>
                  <Route path={'new'} element={<AddSuperStrategyModal />} />
                </Route>
                <Route path={'traders'} element={<TraderTable {...tableProps}/>}>
                  <Route path={'new'} element={<AddTraderModal />} />
                </Route>
                <Route path={'transactionTypes'} element={<TransactionTypeTable {...tableProps}/>}>
                  <Route path={'new'} element={<AddTransactionTypeModal />} />
                </Route>
                <Route path={'transactionSuperTypes'} element={<TransactionSuperTypeTable {...tableProps}/>}>
                  <Route path={'new'} element={<AddTransactionSuperTypeModal />} />
                </Route>
                <Route path='*' element={<Navigate to={REDIRECT_TYPE} replace />}/>
              </Routes>
            </Box>
          </Stack>
        </Page>
      : <NoAccess />
  )
}

export default Glossary
