import React, { useEffect, useState } from 'react'
import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  useTheme
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import ErrorIcon from '@mui/icons-material/Error'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import CheckIcon from '@mui/icons-material/Check'
import { Modal, StringInput } from '@r40cap/ui'
import {
  getStrategyReport as sdkGetStrategy,
  getSnapshotReport as sdkGetSnapshot,
  getCounterpartyPnlReport as sdkGetCounterpartyPnl,
  getStrategyAccountReport as sdkGetStrategyAccount,
  getNewSnapshotReport as sdkGetNewSnapshot
} from '@r40cap/pms-sdk'
import { useAuth } from '@r40cap/auth'

import { type DeskOption } from '../../components/common/types'
import { deskOptions } from '../../components/common/constants'
import { getLastMark } from '../../utils/times'
import DeskSelection from '../../components/common/DeskSelection'

type ReportType = 'cp' | 'ss' | 'st' | 'sa' | 'nss'

interface ResponseStatus {
  state: 'pending' | 'success' | 'failure'
  message: string
}

function getReportTypeDesc (reportType: ReportType): string {
  switch (reportType) {
    case 'cp':
      return 'Counterparty'
    case 'ss':
      return 'Snapshot'
    case 'st':
      return 'Strategy'
    case 'sa':
      return 'Strat-Acc'
    case 'nss':
      return 'NewSnapshot'
  }
}

function ProcessingItemComp (props: {
  reportType: ReportType
  desk: DeskOption
  status: ResponseStatus
}): React.JSX.Element {
  const { reportType, desk, status } = props
  const { palette } = useTheme()

  const repDesc = getReportTypeDesc(reportType)

  const totalText = reportType === 'nss'
    ? `${repDesc} Report`
    : `${desk.label}  ${repDesc} Report`

  return (
    <Stack direction={'column'} spacing={1} alignItems={'center'}>
      <Typography sx={{ color: palette.tableBodyText.main }}>{totalText}</Typography>
      {
        status.state === 'pending'
          ? <CircularProgress sx={{ color: palette.accent.main }}/>
          : status.state === 'success'
            ? <CheckIcon sx={{ color: palette.accent.main }} />
            : <ErrorIcon sx={{ color: palette.accent.main }} />
      }
      {
        status.state === 'failure' && <Typography sx={{ color: palette.error.main }}>{status.message}</Typography>
      }
    </Stack>
  )
}

function ProcessingModal (props: {
  reportType: ReportType
  desk: DeskOption
  goBack: () => void
  status: ResponseStatus
}): React.JSX.Element {
  const { reportType, status, desk } = props

  return (
    <Box
      sx={{ width: '20vw', alignContent: 'center' }}
      padding={5}
    >
      <Stack direction={'column'} sx={{ width: '100%', alignItems: 'center' }} spacing={2}>
        <ProcessingItemComp
          reportType={reportType}
          desk={desk}
          status={status}
        />
      </Stack>
    </Box>
  )
}

function ReportTypeInput (props: {
  reportType: ReportType
  setReportType: (reportType: ReportType) => void
}): React.JSX.Element {
  const { reportType, setReportType } = props
  const { palette } = useTheme()

  const radioStyle = {
    color: palette.accent.main,
    opacity: 0.5,
    '&.Mui-checked': {
      opacity: 0.9,
      color: palette.accent.main
    }
  }

  const radioLabelStyle = {
    color: palette.tableBodyText.main,
    opacity: 0.75
  }

  return (
    <FormControl>
      <FormLabel
        id='demo-radio-buttons-group-label'
        sx={{ color: palette.tableBodyText.main }}
      >Report Type</FormLabel>
      <RadioGroup
        aria-labelledby='demo-radio-buttons-group-label'
        value={reportType}
        onChange={(event) => { setReportType(event.target.value as ReportType) }}
        name='radio-buttons-group'
        row
      >
        <FormControlLabel labelPlacement='bottom' sx={radioLabelStyle} value='st' control={<Radio sx={radioStyle} />} label='Strategy' />
        <FormControlLabel labelPlacement='bottom' sx={radioLabelStyle} value='ss' control={<Radio sx={radioStyle} />} label='Snapshot' />
        <FormControlLabel labelPlacement='bottom' sx={radioLabelStyle} value='cp' control={<Radio sx={radioStyle} />} label='Counterparty' />
        <FormControlLabel labelPlacement='bottom' sx={radioLabelStyle} value='sa' control={<Radio sx={radioStyle} />} label='Strat-Acc' />
        <FormControlLabel labelPlacement='bottom' sx={radioLabelStyle} value='nss' control={<Radio sx={radioStyle} />} label='NewSnapshot' />
      </RadioGroup>
    </FormControl>
  )
}

function EntryModal (props: {
  time: Dayjs
  setTime: (time: Dayjs) => void
  reportType: ReportType
  setReportType: (reportType: ReportType) => void
  desk: DeskOption
  setDesk: (desk: DeskOption) => void
  fileName: string
  setFileName: (fileName: string) => void
  startDownload: () => void
}): React.JSX.Element {
  const {
    time,
    setTime,
    reportType,
    setReportType,
    desk,
    setDesk,
    fileName,
    setFileName,
    startDownload
  } = props
  const { palette } = useTheme()
  const authConext = useAuth()
  const restDesk = authConext.restrictedDeskId ?? undefined

  const handleChangeTime = (newTime: Dayjs | null): void => {
    if (newTime !== null) {
      setTime(newTime)
    }
  }

  useEffect(() => {
    setFileName(`${desk.optionId}_${time.format('YYYY-MM-DD_HH:mm')}_${reportType}`)
  }, [time, reportType, desk])

  return (
    <Box
      sx={{ width: '30vw', alignContent: 'center' }}
      padding={5}
    >
      <Stack direction={'column'} sx={{ width: '100%', alignItems: 'center' }} spacing={2}>
        <DateTimePicker
          value={time}
          format='YYYY-MM-DD HH:mm'
          onChange={handleChangeTime}
          closeOnSelect={false}
          ampm={false}
          timeSteps={{ hours: 1, minutes: 1 }}
        />
        <Stack direction={'row'} spacing={1}>
          <Button
            variant='outlined'
            onClick={() => { setTime(getLastMark()) }}
            sx={{
              color: palette.accent.main,
              width: '50%'
            }}
          >
            Last Mark
          </Button>
          <Button
            variant='outlined'
            onClick={() => { setTime(dayjs().utc()) }}
            sx={{
              color: palette.accent.main,
              width: '50%'
            }}
          >
            Now
          </Button>
        </Stack>
        {
          restDesk === undefined && <DeskSelection
            currentDesk={desk}
            deskChange={setDesk}
          />
        }
        <ReportTypeInput
          reportType={reportType}
          setReportType={setReportType}
        />
        <StringInput
          title='File Name'
          value={fileName}
          setValue={setFileName}
          color={palette.accent.main}
        />
        <Button
          variant='contained'
          onClick={startDownload}
          sx={{
            backgroundColor: palette.accent.main,
            color: palette.primary.main,
            width: '50%'
          }}
        >
          Download
        </Button>
      </Stack>
    </Box>
  )
}

export function ReportButton (): React.JSX.Element {
  dayjs.extend(utc)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [time, setTime] = useState<Dayjs>(dayjs().utc())
  const [status, setStatus] = useState<ResponseStatus>({
    state: 'pending',
    message: ''
  })

  const [desk, setDesk] = useState<DeskOption>(deskOptions[0])
  const [reportType, setReportType] = useState<ReportType>('ss')
  const [fileName, setFileName] = useState<string>(`${desk.optionId}_${time.format('YYYY-MM-DD_HH:mm')}_${reportType}`)

  function closeModal (): void {
    setModalOpen(false)
    setIsProcessing(false)
    setStatus({
      state: 'pending',
      message: ''
    })
  }

  function startDownload (): void {
    setIsProcessing(true)
    if (reportType === 'nss') {
      sdkGetNewSnapshot(time, fileName)
        .then((value) => {
          if (value.success as boolean) {
            setStatus({
              state: 'success',
              message: ''
            })
          } else {
            setStatus({
              state: 'failure',
              message: value.message
            })
          }
        })
        .catch(() => {
          setStatus({
            state: 'failure',
            message: 'Unexpected Error'
          })
        })
    } else {
      const reportFunction = reportType === 'cp'
        ? sdkGetCounterpartyPnl
        : reportType === 'ss'
          ? sdkGetSnapshot
          : reportType === 'st'
            ? sdkGetStrategy
            : sdkGetStrategyAccount
      reportFunction(time, fileName, desk.deskIds)
        .then((value) => {
          if (value.success as boolean) {
            setStatus({
              state: 'success',
              message: ''
            })
          } else {
            setStatus({
              state: 'failure',
              message: value.message
            })
          }
        })
        .catch(() => {
          setStatus({
            state: 'failure',
            message: 'Unexpected Error'
          })
        })
    }
  }

  return (
    <>
      <IconButton onClick={() => { setModalOpen(true) }}>
        <FileDownloadIcon/>
      </IconButton>
      <Modal
        open={modalOpen}
        handleClose={closeModal}
      >
        {
          isProcessing
            ? <ProcessingModal
                reportType={reportType}
                desk={desk}
                goBack={() => { setIsProcessing(false) }}
                status={status}
              />
            : <EntryModal
                time={time}
                setTime={setTime}
                reportType={reportType}
                setReportType={setReportType}
                desk={desk}
                setDesk={setDesk}
                fileName={fileName}
                setFileName={setFileName}
                startDownload={startDownload}
              />
        }
      </Modal>
    </>
  )
}
