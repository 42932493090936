import React, { useEffect, useMemo, useState } from 'react'
import {
  IconButton,
  TableCell,
  TableRow,
  useTheme
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { type ColumnDefinition, type Order, ValueCell, getComparator, stableSort } from '@r40cap/ui'
import type { Algo, AlgoPosition } from '@r40cap/algos-sdk'

import type { AlgoPositionRow } from '../types'
import BaseFxRow from './BaseFxRow'

function getUniqueBases (positions: AlgoPosition[]): string[] {
  const allBases = new Set(positions.map((row) => row.baseFx))
  return Array.from(allBases)
}

function AlgoRow (props: {
  rowName: string
  bottomRows: AlgoPosition[]
  order: Order
  orderBy: keyof AlgoPositionRow
  columns: Array<ColumnDefinition<AlgoPositionRow, any>>
  isOpen: boolean
  toggleOpen: () => void
  openChildren: readonly string[]
  openChild: (childId: string) => void
  baseTickerToPrice?: Map<string, number>
  pxIsLoading: boolean
  algoDetails?: Algo
}): React.JSX.Element {
  const {
    rowName,
    bottomRows,
    order,
    orderBy,
    columns,
    isOpen,
    toggleOpen,
    openChildren,
    openChild,
    baseTickerToPrice,
    pxIsLoading,
    algoDetails
  } = props
  const [baseFxs, setBaseFxs] = useState<string []>(getUniqueBases(bottomRows))
  const { palette } = useTheme()

  useEffect(() => {
    const uniqueBases = getUniqueBases(bottomRows)
    setBaseFxs(uniqueBases)
  }, [bottomRows])

  const netQuantity = pxIsLoading
    ? undefined
    : bottomRows.reduce((sum, current) => {
      const size = current.psn * current.multiplier
      const price = baseTickerToPrice?.get(current.baseFx) ?? 0
      return sum + (size * price)
    }, 0)
  const grossQuantity = pxIsLoading
    ? undefined
    : bottomRows.reduce((sum, current) => {
      const size = current.psn * current.multiplier
      const price = baseTickerToPrice?.get(current.baseFx) ?? 0
      return sum + Math.abs(size * price)
    }, 0)

  const algoRowObject: AlgoPositionRow = {
    rowName,
    netQuantity,
    grossQuantity,
    quantityDecimals: 2,
    colorSign: algoDetails === undefined
      ? 0
      : algoDetails.description.target !== 'neutral'
        ? 0
        : Math.abs(netQuantity ?? 0) > 100
          ? -1
          : 1
  }

  const visibleBases = useMemo(
    () => {
      return stableSort(baseFxs, (a, b) => {
        const baseARows = bottomRows.filter((val) => val.baseFx === a)
        const psnRowA: AlgoPositionRow = {
          rowName: a,
          netQuantity: baseARows.reduce((sum, current) => sum + (current.psn * current.multiplier), 0),
          grossQuantity: baseARows.reduce((sum, current) => sum + Math.abs(current.psn * current.multiplier), 0),
          quantityDecimals: 4,
          colorSign: 0
        }
        const baseBRows = bottomRows.filter((val) => val.baseFx === b)
        const psnRowB: AlgoPositionRow = {
          rowName: b,
          netQuantity: baseBRows.reduce((sum, current) => sum + (current.psn * current.multiplier), 0),
          grossQuantity: baseBRows.reduce((sum, current) => sum + Math.abs(current.psn * current.multiplier), 0),
          quantityDecimals: 4,
          colorSign: 0
        }
        return getComparator(order, orderBy)(psnRowA, psnRowB)
      })
    },
    [order, orderBy, bottomRows]
  )

  return (
    <>
      <TableRow>
        <TableCell sx={{ padding: 1 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={toggleOpen}
          >
            {
              isOpen
                ? <KeyboardArrowUpIcon fontSize='small'/>
                : <KeyboardArrowDownIcon fontSize='small'/>
            }
          </IconButton>
        </TableCell>
        {
          columns.map((column, idx) => (
            <ValueCell<AlgoPositionRow, any>
              column={
                column.id === 'grossQuantity' || column.id === 'netQuantity'
                  ? { ...column, valueDetails: { ...column.valueDetails, prefix: '$' } }
                  : column
              }
              item={algoRowObject}
              key={column.id}
              defaultTextColor={palette.tableBodyText.main}
              redTextColor='red'
              greenTextColor='green'
              dense
            />
          ))
        }
      </TableRow>
      {isOpen && visibleBases.map((base) => {
        return (
          <BaseFxRow
            key={base}
            bottomRows={bottomRows.filter((row) => row.baseFx === base)}
            order={order}
            orderBy={orderBy}
            columns={columns}
            isOpen={openChildren.includes(base)}
            toggleOpen={() => { openChild(base) }}
            allowance={
              algoDetails === undefined
                ? undefined
                : algoDetails.description.target !== 'neutral'
                  ? undefined
                  : algoDetails.description.neutralAllowanceMap[base]
            }
          />
        )
      })}
    </>
  )
}

export default AlgoRow
