import React from 'react'
import { TableHead, TableRow, useTheme } from '@mui/material'
import { type ColumnDefinition, HeaderCell, type ObjectWithId, type Order } from '@r40cap/ui'

import type { InputType } from './types'

interface SortableHeaderProps<R extends ObjectWithId<string>, O> {
  onRequestSort: (property: keyof R) => void
  order: Order
  orderBy: keyof R
  columns: Array<ColumnDefinition<R, InputType, O, string>>
}

function SortableHeader<R extends ObjectWithId<string>, O> (props: SortableHeaderProps<R, O>): React.JSX.Element {
  const {
    onRequestSort,
    order,
    orderBy,
    columns
  } = props
  const { palette } = useTheme()

  const createSortHandler = (property: keyof R): void => {
    onRequestSort(property)
  }

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, idx) => (
          <HeaderCell<R, any>
            column={column}
            isActiveSort={orderBy === column.id}
            order={order}
            sortHandler={createSortHandler}
            key={column.label}
            fontSizeOverride={13}
            backgroundColor={palette.background.default}
            textColor={palette.tableHeaderText.main}
            activeTextColor={palette.accent.main}
          />
        ))}
      </TableRow>
    </TableHead>
  )
}

export default SortableHeader
