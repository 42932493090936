import React from 'react'
import { Box, Stack, useTheme } from '@mui/material'
import { RefreshButton } from '@r40cap/ui'

function AlgosControlsPanel (props: {
  refreshFunction: () => void
}): React.JSX.Element {
  const { refreshFunction } = props
  const { palette } = useTheme()

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          width: '20%',
          minWidth: '150px'
        }}
      />
      <Stack
        direction={'row'}
        spacing={1}
        alignContent={'center'}
        sx={{ height: '50%' }}
      >
        <RefreshButton
          refreshFunction={refreshFunction}
          iconColor={palette.primary.main}
          buttonColor={palette.accent.main}
        />
      </Stack>
    </Box>
  )
}

export default AlgosControlsPanel
